import {IconButton} from "../../Components/IconButton";
import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {config} from "../../config";
import {getSize} from "./UploadedImageCard";


export const SearchUploadedImages = (props) => {

    const [title, setTitle] = useState(null);
    const [width, setWidth] = useState([0, 100]);
    const [height, setHeight] = useState([0, 100]);
    const [size, setSize] = useState([0, 100]);

    const [dimensions, setDimensions] = useState({
        width: [0, 100],
        height: [0, 100],
        size: [0, 100]
    });

    useEffect(() => {
        axios.get(config.uploadedImages.endpoints.criteria)
            .then((res) => {
                if (res.data) {
                    setDimensions(res.data);
                }
            })
    }, []);

    useEffect(() => {
        setWidth([dimensions.width[0], dimensions.width[1]]);
        setHeight([dimensions.height[0], dimensions.height[1]]);
        setSize([dimensions.size[0], dimensions.size[1]]);
    }, [dimensions]);

    const handleWidth = (event, newValue) => {
        setWidth(newValue);
    };

    const handleHeight = (event, newValue) => {
        setHeight(newValue);
    };

    const handleSize = (event, newValue) => {
        setSize(newValue);
    };

    const resetState = () => {
        setTitle(null);

        if (typeof props.onResetState === 'function') {
            props.onResetState();
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();

        if (typeof props.onSearchSubmitted === 'function') {
            const params = {
                title: title,
                width: width,
                height: height,
                size: size
            }

            props.onSearchSubmitted(params);
        }
    }

    return (
        <div className={"col-12"}>
            <div className="card mg-b-20">
                <form onSubmit={onSubmit}>
                    <div className="row card-body">
                        <div className={"col-md-6 mg-b-10"}>
                            <div className="form-group mg-b-0">
                                <label className="form-label">Filter by title: </label>
                                <input
                                    className="form-control"
                                    name="firstname"
                                    placeholder="Title"
                                    type="text"
                                    value={title ?? ''}
                                    onChange={(event) => setTitle(event.target.value)}
                                />
                            </div>
                        </div>

                        <div className={"col-md-6  mg-b-10"}>
                            <div className="form-group mg-b-0">
                                <label className="form-label">Filter by size</label>
                                <Box sx={{width: "100%"}}>
                                    <Slider
                                        value={size}
                                        onChange={handleSize}
                                        valueLabelDisplay="auto"
                                        valueLabelFormat={getSize}
                                        step={1024}
                                        min={dimensions.size[0]}
                                        max={dimensions.size[1]}
                                    />
                                </Box>
                            </div>
                        </div>

                        <div className={"col-md-6"}>
                            <div className="form-group mg-b-0">
                                <label className="form-label">Filter by width</label>
                                <Box sx={{width: "100%"}}>
                                    <Slider
                                        value={width}
                                        onChange={handleWidth}
                                        valueLabelDisplay="auto"
                                        valueLabelFormat={value => `${value} px`}
                                        min={dimensions.width[0]}
                                        max={dimensions.width[1]}
                                    />
                                </Box>
                            </div>
                        </div>

                        <div className={"col-md-6"}>
                            <div className="form-group mg-b-0">
                                <label className="form-label">Filter by height</label>
                                <Box sx={{width: "100%"}}>
                                    <Slider
                                        value={height}
                                        onChange={handleHeight}
                                        valueLabelDisplay="auto"
                                        valueLabelFormat={value => `${value} px`}
                                        min={dimensions.height[0]}
                                        max={dimensions.height[1]}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>

                    <div className={"card-footer d-flex justify-content-between align-items-center"}>
                        <a href="#" className="float-right btn btn-light rounded-5"
                           onClick={resetState}>Cancel</a>

                        <IconButton
                            text={"Search"}
                            icon={"search"}
                            onClick={onSubmit}
                            loader={false}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
