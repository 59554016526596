import {config} from "../config";
import {CustomAlert, showToast} from "../Components/CustomAlerts";
import {addCustomAttributeRepeaterToLayout} from "../../helpers";

export function getCollection(id, successCallback, errorCallback, loaderCallback) {
    loaderCallback(true)
    axios.get(config.collections.endpoints.find(id))
        .then(res => {
            res.data.layout = addCustomAttributeRepeaterToLayout(res.data.layout);
            successCallback(res.data)
        })
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}


export function getStatus(id, successCallback, errorCallback, loaderCallback) {
    loaderCallback(true);
    axios.get(config.collections.endpoints.getAllowedStatus(id))
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function updateStatus(id, status, successCallback, loaderCallback) {
    loaderCallback(true);

    axios.put(config.collections.endpoints.updateStatus(id), {status: status})
        .then(res => {
            if (typeof successCallback === 'function') {
                successCallback(res.data);
            }

            showToast("Collection status updated successfully");
        })
        .catch(err => showToast(err?.response?.data?.message ?? 'Error Occurred', 'error'))
        .finally(() => loaderCallback(false))
}

export function deleteCollection(collection, successCallback, loaderCallback) {
    const deleteFunction = () => {
        loaderCallback(true);
        axios.delete(config.collections.endpoints.delete(collection.id))
            .then((res) => {
                if(collection.status.label === 'Removed') {
                    window.location.href = config.collections.endpoints.index;
                    return ;
                }

                if (typeof successCallback === 'function') {
                    successCallback(res.data);
                }

                showToast("Collection deleted successfully");
            })
            .catch(err => showToast(err?.response?.data?.message ?? 'Error Occurred', 'error'))
            .finally(() => loaderCallback(false))
    }

    if (collection.status.label === 'Removed') {
        CustomAlert("Permanently Delete Collection", "Are you sure you want to permanently delete this collection? <br> You can not revert this.", deleteFunction)
    } else {
        deleteFunction();
    }
}



export function restoreCollection(collection, successCallback, loaderCallback) {
    loaderCallback(true);

    axios.put(config.collections.endpoints.restore(collection.id))
        .then((res) => {
            if (typeof successCallback === 'function') {
                successCallback(res.data);
            }

            showToast("Collection restored successfully");
        })
        .catch(err => showToast(err?.response?.data?.message ?? 'Error Occurred', 'error'))
        .finally(() => loaderCallback(false))
}

export function sortCollectionProducts(collection, products, successCallback, loaderCallback) {
    const data = products.map((item, index) => {
        return {id: item.id, sort: index}
    })

    loaderCallback(true)
    axios.put(config.collectionProducts.endpoints.sort(collection.id), {data: data})
        .then(res => {
            successCallback(res.data);
            showToast("Saved successfully");
        })
        .catch(err => showToast(err?.response?.data?.message ?? 'Error Occurred', 'error'))
        .finally(() => loaderCallback(false))
}
