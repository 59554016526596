import Tooltip from "@mui/material/Tooltip";
import FeatherIcon from "feather-icons-react";
import React, {useEffect, useRef, useState} from "react";
import {IconButton} from "../IconButton";
import {sortDraggedItem} from "../../../helpers";

export default function CustomSimpleTextInputRepeater({defaultValue, title, description, label, placeholder, onChange, name}) {
    const [values, setValues] = useState(defaultValue ?? []);
    const draggingItemRef = useRef();
    const placingDraggedItemRef = useRef();
    const onDragStart = index => draggingItemRef.current = index;
    const onDragOver = index => placingDraggedItemRef.current = index;

    const onDragEnd = () => sortDraggedItem(values, draggingItemRef.current, placingDraggedItemRef.current, setValues);

    useEffect(() => {
        if (values.length && typeof onChange === 'function') {
            onChange(name, values);
        }
    }, [values]);

    useEffect(() => {
        if (defaultValue && defaultValue.length && (!values || !values.length)) {
            setValues(defaultValue);
        }
    }, [defaultValue])

    const onInputChange = (i, value) => {
        setValues(current => {
            current[i] = value;

            return [...current];
        })
    }

    const addNewRow = () => {
        setValues((current) => {
            return [...current, ''];
        });
    }

    const onRemove = (i) => {
        setValues((current) => {
            if(current.length >= (i+1)) {
                current.splice(i, 1);
            }
            return [...current];
        })
    }

    const getRepeaterInputs = () => {
        let inputs = values.map((item, index) => {
            return (
                <div
                    className={"pos-relative bd-t bd-2 pd-t-10 mg-b-10"} key={index}
                    draggable={true}
                    onDragStart={() => onDragStart(index)}
                    onDragOver={() => onDragOver(index)}
                    onDragEnd={onDragEnd}
                    onDragOverCapture={(e) => e.preventDefault()}
                >
                    <div className="form-group mg-b-0">
                        <label className="form-label">{label}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={placeholder}
                            value={item}
                            onChange={(e) => onInputChange(index, e.target.value)}
                        />

                    </div>
                    <div className={"pos-absolute t-5 r-5"}>
                        <IconButton
                            className={"btn btn-danger rounded-30 p-0 justify-content-center wd-25-f ht-25-f"}
                            icon={'x-circle'}
                            tooltip={"Delete row"}
                            onClick={() => onRemove(index)}
                            loader={false}
                        />
                    </div>
                </div>

            )
        });

        return <div className={""}>{inputs}</div>;
    }

    return (
        <div className={"mg-b-10 bd bd-4 pd-10"}>
            <div className={"d-flex justify-content-between align-items-center"}>
                <div>
                    <h6>{title}</h6>
                    <p>{description}</p>
                </div>
                <div>
                    <Tooltip title="Add new row" arrow placement={"top"}>
                        <button className="btn btn-indigo btn-icon p-0 rounded ml-3" onClick={addNewRow}>
                            <FeatherIcon icon={"plus-circle"}/>
                        </button>
                    </Tooltip>
                </div>
            </div>

            {getRepeaterInputs()}
        </div>
    )
}
