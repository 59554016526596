export const Loader = (props) => {
    return (
        <div className={`dimmer active ${props.className ?? ''}`} style={props.style ?? {}}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
