import {config} from "../config";
import {CustomAlert, showToast} from "../Components/CustomAlerts";

export function getSeoMetaTags(successCallback, errorCallback, loaderCallback, filters = {}) {
    if (typeof loaderCallback === 'function') {
        loaderCallback(true)
    }

    axios.get(config.seoMetaTags.endpoints.get, {params: filters})
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => {
            if (typeof loaderCallback === 'function') {
                loaderCallback(false)
            }
        })
}

export function storeMetaTag(data, successCallback, errorCallback, loaderCallback) {
    loaderCallback(true)
    axios.post(config.seoMetaTags.endpoints.store, data)
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function updateMetaTag(seoMetaTag, data, successCallback, errorCallback, loaderCallback) {
    loaderCallback(true)
    axios.patch(config.seoMetaTags.endpoints.update(seoMetaTag.id), data)
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function deleteMetaTag(seoMetaTag, successCallback, loaderCallback) {
    const deleteFunction = () => {
        axios.delete(config.seoMetaTags.endpoints.delete(seoMetaTag.id))
            .then((res) => {
                showToast("SEO Meta tag successfully deleted");
                successCallback(res.data);
            })
            .catch(err => console.error(err?.response?.data?.message ?? 'Error Occurred'))
            .finally(() => loaderCallback(false))
    }

    if (seoMetaTag.status === 'Removed') {
        CustomAlert(
            `Permanently Delete "${seoMetaTag.name}" meta tag`,
            "Are you sure you want to <strong> permanently delete </strong> this meta tag?",
            deleteFunction,
        );
    } else {
        deleteFunction();
    }
}

export function restoreMetaTag(seoMetaTag, successCallback, loaderCallback) {
    loaderCallback(true)
    axios.put(config.seoMetaTags.endpoints.restore(seoMetaTag.id))
        .then(res => {
            showToast("SEO Meta tag successfully restored");
            successCallback(res.data);
        })
        .catch(err => console.error(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function getSelectedPageModels(page, successCallback, loaderCallback) {
    if (page && page in config.seoMetaTags.pageSelector.models) {
        loaderCallback(true);
        axios.get(config.seoMetaTags.pageSelector.models[page].endpoint)
            .then((res) => {
                const data = res.data.map(item => {
                    return {
                        label: item[config.seoMetaTags.pageSelector.models[page].labelKey ?? 'name'],
                        value: item[config.seoMetaTags.pageSelector.models[page].valueKey ?? 'id'],
                    }
                });

                successCallback(data ?? []);
            })
            .catch(err => successCallback([]))
            .finally(() => loaderCallback(false));
    } else {
        successCallback([]);
    }
}
