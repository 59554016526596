import React, {useState} from "react";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";
import {deletePromotion, restorePromotion} from "../../Services/PromotionService";

export const ActionButtons = ({promotion, onEdit, setPromotions}) => {
    const [loader, setLoader] = useState(false);

    const onDelete = () => deletePromotion(promotion, setPromotions, setLoader);
    const onRestore = () => restorePromotion(promotion, setPromotions, setLoader);

    const title = promotion.status === 'Removed' ? 'Permanently Delete' : 'Delete';
    const icon = promotion.status === 'Removed' ? 'trash' : 'trash-2';

    return (
        <div className={"d-flex justify-content-start"}>
            <Tooltip title="Edit promotion" arrow placement={"top"}>
                <button className="btn btn-indigo btn-icon p-0 rounded"
                        onClick={onEdit}>
                    <FeatherIcon icon={"edit"}/>
                </button>
            </Tooltip>

            <Tooltip title={title} arrow placement={"top"}>
                <button className={`btn btn-danger btn-icon p-0 rounded ml-3`} disabled={loader} onClick={onDelete}>
                    <FeatherIcon icon={loader ? 'loader' : icon}/>
                </button>
            </Tooltip>

            {(promotion.status === 'Removed') &&
            <Tooltip title="Restore" arrow placement={"top"}>
                <button className={`btn btn-info btn-icon p-0 rounded ml-3`} disabled={loader} onClick={onRestore}>
                    <FeatherIcon icon={loader ? 'loader' : 'rotate-ccw'}/>
                </button>
            </Tooltip>
            }
        </div>
    )
}
