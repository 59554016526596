import {CustomModal} from "../../Components/CustomModal";
import React from "react";
import {config} from "../../config";
import {CustomForm} from "../../Components/CustomForm/CustomForm";

export const PromotionForm =  ({promotion = null, visible, onHide}) => {
    const endpoints = {
        layouts: config.promotions.endpoints.getLayouts,
        update: config.promotions.endpoints.update(promotion?.id ?? null),
        edit: config.promotions.endpoints.find(promotion?.id ?? null),
        store: config.promotions.endpoints.store,
        index: config.promotions.endpoints.index
    };

    return (
        <PromotionFormModal
            visible={visible}
            onHide={onHide}
            promotion={promotion}
        >
            <CustomForm
                endpoints={endpoints}
                layout={config.promotions.customFormInputs(config.promotions.endpoints.getLayouts)}
                editModel={promotion?.id ?? null}
            />
        </PromotionFormModal>
    )
}


export const PromotionFormModal = ({visible, onHide, children, promotion}) => {

    const hideModel = () => {
        if(typeof onHide === 'function') {
            onHide(false)
        }
    }

    return (
        <CustomModal
            visible={visible}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h6 className="modal-title">
                        {
                            promotion ?
                                `Edit promotion ${promotion?.title ?? ""}`
                                :
                                `Add new promotion`
                        }
                    </h6>
                    <button aria-label="Close" className="close" type="button" onClick={hideModel}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className={"card"} style={{backgroundColor: "#ffffff3d"}}>
                        <div className={"card-body"}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}
