import {CustomModal} from "../Components/CustomModal";
import React, {useEffect, useMemo, useState} from "react";
import {Slide} from "react-slideshow-image";
import {LayoutDataContainer} from "../Components/CustomForm/LayoutDataTable";
import {addCollectionCustomAttributeProductInputs} from "../../helpers";

export const CollectionProductModal = ({product, collection, visible, onHide}) => {
    const [selectedLayoutInputs, setSelectedLayoutInputs] = useState(product?.layout?.inputs ?? []);

    const hideModel = () => {
        if (typeof onHide === 'function') {
            onHide(false)
        }
    }

    useEffect(() => {
        if(product) {
            const newInputs = addCollectionCustomAttributeProductInputs(collection, product?.layout ?? {inputs: []}, '', false);
            setSelectedLayoutInputs(newInputs.inputs)
        }
    }, [product])

    return (
        <CustomModal
            visible={visible}
        >
            {product &&
            <div className="modal-content">
                <div className="modal-header">
                    <h6 className="modal-title">
                        View Product
                    </h6>
                    <button aria-label="Close" className="close" type="button" onClick={hideModel}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className={"card"} style={{backgroundColor: "#ffffff3d"}}>
                        <div className={"card-body"}>
                            <div className={"d-flex"}>
                                <div className={"product-slideshow wd-300"}>
                                    <Slide>
                                        {product.images.map(((image, index) => (
                                            <div key={index} className="each-slide">
                                                <img className={"wd-300 ht-300"} src={image.url} alt={image.title}/>
                                            </div>
                                        )))}
                                    </Slide>

                                    <div className={"mg-t-10"}>
                                        <h3>{product.name}</h3>
                                        <p>({product.textual_id})</p>
                                        <h4>{product.details}</h4>
                                    </div>


                                </div>
                                <div className={"mg-l-10 pd-l-20 w-100 bd-l"}>
                                    <h6>Layout - {product.layout.title}</h6>
                                    <LayoutDataContainer
                                        inputs={selectedLayoutInputs}
                                        data={product.layout_data ?? {} }
                                    />

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            }
        </CustomModal>
    )
}

