import React, {useEffect, useState} from "react";

export const CustomRatingInput = ({defaultValue, input, onReady, onChange}) => {
    const [text, setText] = useState(defaultValue?.text ?? "");
    const [score, setScore] = useState(defaultValue?.score ?? 5);
    const divCustomClasses = input.divCustomClasses ?? "col-md-6 mg-b-10";

    useEffect(() => {
        if(typeof onReady === 'function') {
            onReady()
        }
    }, []);

    useEffect(() => {
        if(typeof defaultValue == 'object') {
            if ('text' in defaultValue) {
                setText(defaultValue.text);
            }

            if ('score' in defaultValue) {
                setScore(defaultValue.score);
            }
        }
    }, [defaultValue])

    const onTextChange = (event) => {
        const value = event.target.value;
        setText(value);
        onRatingChange({text: value});
    }

    const onScoreChange = (event) => {
        const value = event.target.value;
        setScore(value);
        onRatingChange({score: value});
    }

    const onRatingChange = (obj) => {
        const rating = {text: obj.text ?? text, score: obj.score ?? score};
        if (typeof onChange == 'function') {
            onChange(input.id, rating);
        }
    }

    return (
        <div className={divCustomClasses}>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <div className="form-group mg-b-0">
                        <label className="form-label">{input.title} score</label>
                        <input
                            type="number"
                            step=".1"
                            className="form-control"
                            placeholder={input.description}
                            value={score ?? ""}
                            onChange={onScoreChange}
                        />
                    </div>
                </div>

                <div className={"col-md-8"}>
                    <div className="form-group mg-b-0">
                        <label className="form-label">{input.title} text</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={input.description}
                            value={text ?? ""}
                            onChange={onTextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
