export const countriesResultTemplate = `
<span>
    {{#config.showFlag}} <strong>{{item.flag}} </strong>{{/config.showFlag}} 
    {{#config.showText}}
        {{#config.showCode}}
            {{item.code}}
        {{/config.showCode}}
        
        {{^config.showCode}}
            {{item.name}}
        {{/config.showCode}}
    {{/config.showText}}
    
    {{#config.showPhoneCode}}
        <strong>+{{item.phoneCode}}</strong>
    {{/config.showPhoneCode}}
</span>
`;

export const categoriesTemplate = `
    <span>{{item.name}}</span>
`;
