import {IconButton} from "../../Components/IconButton";
import React, {useState} from "react";
import axios from "axios";
import {config} from "../../config";
import {showToast} from "../../Components/CustomAlerts";

export const UploadImageNewFile = (props) => {
    const [image, setImage] = useState(null);
    const [title, setTitle] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isUrlImage, setIsUrlImage] = useState(true);
    const [imageUrl, setImageUrl] = useState(null);


    const onFileChange = event => {
        setImage(event.target.files[0]);
        setTitle(event.target.files[0].name.split('.').slice(0, -1).join('.'));
    };

    const resetState = () => {
        setImage(null);
        setTitle(null);
        setError(null);
        setLoading(false);

        if(typeof props.onResetState === 'function') {
            props.onResetState();
        }
    }

    const onImageUrlChanged = (e) => {
        setImageUrl(e.target.value);
        const filename = (e.target.value ?? "").split('/').pop() ?? "";
        const filenameArr = filename.split('.');
        setTitle(filenameArr.length > 1 ? filenameArr.slice(0, -1).join('.') : filenameArr.join('.'));
    }

    const onFileUpload = () => {
        if (((!isUrlImage && image) || (isUrlImage && imageUrl)) && title && title.length > 0) {
            const formData = new FormData();
            formData.append('title', title);
            isUrlImage ?
                formData.append('url', imageUrl) :
                formData.append('file', image, image.name);


            setLoading(true);
            axios.post(config.uploadedImages.endpoints.store, formData)
                .then((res) => {
                    if (res.data.uploaded) {
                        resetState();
                        showToast("Image uploaded successfully")

                        if (typeof props.onUploadComplete === 'function') {
                            props.onUploadComplete(res.data.image);
                        }
                    } else {
                        setError('Error occurred, please try again');
                    }
                })
                .catch((err) => {
                    setError(err?.response?.data?.message ?? 'Error occurred, please try again');
                    showToast("Error occurred, please try again", "error");
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            setError('Please select an image to upload');
        }
    }

    return (
        <div className={"col-12"}>
            <div className="card mg-b-20">
                <div className="row card-body pd-b-0">
                    <div className={"col-md-6"}>
                        {isUrlImage ?
                            <div className="form-group mg-b-10">
                                <label className="form-label">Image URL: <span
                                    className="tx-danger">*</span></label>
                                <input
                                    className="form-control"
                                    name="image_url"
                                    placeholder="Enter image URL"
                                    type="url"
                                    value={imageUrl ?? ''}
                                    onChange={onImageUrlChanged}
                                />
                            </div>
                            :
                            <div className="form-group mg-b-2">
                                <label className="form-label">Image: <span
                                    className="tx-danger">*</span></label>
                                <div className="custom-file-upload d-flex">
                                    <input
                                        className={"d-none"}
                                        type="file"
                                        id="upload_image_file"
                                        onChange={onFileChange}
                                    />
                                    <label className="file_name_label">{image?.name ?? 'Choose file'}</label>
                                    <label htmlFor={'upload_image_file'}
                                           className="browse_file_label">Browse</label>
                                </div>
                            </div>
                        }
                        <div className={'mg-t-2'}>
                            <input
                                id={"upload_by_url"}
                                type={"checkbox"}
                                checked={isUrlImage}
                                onChange={() => setIsUrlImage(current => !current)}
                            />
                            <label htmlFor={"upload_by_url"} className={"mg-l-2"}>Upload by URL</label>
                        </div>

                    </div>

                    <div className={"col-md-6"}>
                        <div className="form-group mg-b-0">
                            <label className="form-label">Title: <span
                                className="tx-danger">*</span></label>
                            <input
                                className="form-control"
                                name="firstname"
                                placeholder="Enter title for this image"
                                type="text"
                                value={title ?? ''}
                                onChange={(event) => setTitle(event.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className={"card-footer d-flex justify-content-between align-items-center"}>
                    <a href="#" className="float-right btn btn-light rounded-5"
                       onClick={resetState}>Cancel</a>
                    {error &&
                    <p className={"text-center text-danger m-0"}>{error}</p>
                    }

                    <IconButton
                        text={"Upload"}
                        icon={"upload"}
                        onClick={onFileUpload}
                        loader={loading}
                    />
                </div>
            </div>
        </div>
    )
}
