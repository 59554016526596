import {CSSTransition} from 'react-transition-group';
import {useRef} from "react";

export const CustomModal = ({visible, children, className="modal-dialog modal-dialog-scrollable modal-xl"}, style={}) => {
    const nodeRef = useRef(null);

    return (
        <CSSTransition
            in={visible}
            nodeRef={nodeRef}
            timeout={300}
            classNames="alert"
            unmountOnExit
        >
            <div
                ref={nodeRef}
                className="modal show"
                style={{display: "block"}}
            >
                <div className={className} role="document" style={style}>
                    {children}
                </div>
            </div>
        </CSSTransition>
    );
}
