import Stats from "./Partials/Stats";
import HistoryGraph from "./Partials/HistoryGraph";
import Filters from "./Partials/Filters";
import React, {useCallback, useState} from "react";
import ResponsePieChart from "./Partials/ResponsePieChart";

export default function Dashboard() {
    const [filters, setFilters] = useState({}),

        handleFiltersClear = useCallback(() => {
            setFilters({})
        }, []),
        handleOnFilterChange = useCallback((key, value) => {
            if (key) {
                setFilters(c => ({...c, [key]: value ?? null}))
            }
        }, [])

    return (
        <>
            <Filters filters={filters}
                     onFilterChange={handleOnFilterChange}
                     onClear={handleFiltersClear}
            />

            <div className="row">
                <Stats filters={filters} onFilterChange={handleOnFilterChange}/>
                <ResponsePieChart filters={filters}/>
                <HistoryGraph filters={filters}/>
            </div>


        </>
    )
}
