import React, {useEffect, useState} from "react";
import {LayoutInputs} from "../LayoutInputs";
import {CustomSelect} from "./CustomSelect";
import {config} from "../../../config";
import {addCustomAttributeRepeaterToLayout} from "../../../../helpers";

export const CustomLayoutSelector = (props) => {
    const [layout, setLayout] = useState(null);
    const [layouts, setLayouts] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);

    const [areLayoutsLoaded, setAreLayoutsLoaded] = useState(false);

    let layoutData = props.dataDefaultValues ?? {};

    useEffect(() => {
        axios.get(props.input.endpoint)
            .then(res => {
                setLayouts(transformLayouts(res.data ?? []));
                setSelectOptions(res.data.map(item => {
                    return {value: item.id, label: item.title}
                }));

                setAreLayoutsLoaded(true);
            })
            .catch(err => {
                setSelectOptions([{value: "", label: "Error occurred"}]);
            })
    }, []);

    useEffect(() => {
        if(props.defaultValue && layout?.id !== props.defaultValue) {
            const selectedLayout = layouts.find((item) => {
                return item.id === props.defaultValue;
            })

            if (selectedLayout) {
                setLayout(selectedLayout);
            }
        }
    }, [props.defaultValue])

    useEffect(() => {
        if(selectOptions.length && areLayoutsLoaded && typeof props.onReady === 'function') {
            props.onReady()
        }
    }, [layouts, areLayoutsLoaded, selectOptions]);

    const transformLayouts = (layouts) => {
        return layouts.map(item => addCustomAttributeRepeaterToLayout(item))
    }

    const onChangeLayout = (id, value) => {
        const selectedLayout = layouts.find((item) => {
            return item.id === value;
        })

        if (selectedLayout) {
            setLayout(selectedLayout);
            props.onChange(props.input.id, selectedLayout.id);
        } else {
            setLayout(null);
            props.onChange(props.input.id, null);
        }
    }

    const onChangeLayoutData = (id, value) => {
        props.onChange(`${props.input.formDataKey ?? 'layout_data'}.${id}`, value);
    }

    const selectInput = {
        id: props.input.id,
        options: selectOptions,
        title: props.input.title,
        description: props.input.description
    };

    return (
        <React.Fragment>
            <CustomSelect defaultValue={props.defaultValue ?? ""} input={selectInput} onChange={onChangeLayout} />

            <div className={"col-12"}>
                <div className={"row mg-t-10"}>
                    <LayoutInputs
                        layout={layout}
                        onChange={onChangeLayoutData}
                        defaultValue={props.dataDefaultValues ?? null}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}
