import {CustomModal} from "../Components/CustomModal";
import React, {useEffect, useState} from "react";
import {config} from "../config";
import {CustomForm} from "../Components/CustomForm/CustomForm";
import {addCollectionCustomAttributeProductInputs, transformCustomAttributes} from "../../helpers";

export const CollectionProductForm =  ({visible, onHide, collection, product = null}) => {
    const endpoints = {
        layouts: config.products.endpoints.getLayouts,
        update: config.collectionProducts.endpoints.update(collection?.id ?? "", product?.id ?? ""),
        edit: config.collectionProducts.endpoints.find(collection?.id ?? "",product?.id ?? ""),
        store: config.collectionProducts.endpoints.store(collection?.id ?? ""),
        index: config.collectionProducts.endpoints.index(collection?.id ?? "")
    };

    const [inputs, setInputs] = useState(config.products.customFormInputs(config.products.endpoints.getLayouts));

    useEffect(() => {
        setInputs((current) => addCollectionCustomAttributeProductInputs(collection, current));
    }, [])

    return (
        <CollectionProductFormModal visible={visible} onHide={onHide} collection={collection} product={product}>
            <CustomForm
                endpoints={endpoints}
                layout={inputs}
                textualIdGeneratorKey={"name"}
                editModel={product?.id ?? null}
            />
        </CollectionProductFormModal>
    )
}


export const CollectionProductFormModal = ({visible, onHide, children, collection, product}) => {

    const hideModel = () => {
        if(typeof onHide === 'function') {
            onHide(false)
        }
    }

    return (
        <CustomModal
            visible={visible}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h6 className="modal-title">
                        {
                            product ?
                                `Edit product ${collection?.name ?? ""} > ${product.name} `
                                :
                                `Add new product to ${collection?.name ?? ""}`
                        }
                    </h6>
                    <button aria-label="Close" className="close" type="button" onClick={hideModel}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className={"card"} style={{backgroundColor: "#ffffff3d"}}>
                        <div className={"card-body"}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}
