import {useCallback, useEffect, useState} from "react"
import {config} from "../../config"

export default function useHistoryGraph(filters) {
    const [periods, setPeriods] = useState([]),
        [series, setSeries] = useState([]),

        handleResponseSeries = useCallback((data, params) => {
            const series = []

            if (Array.isArray(data?.series?.total)) {
                series.push({
                    name: "Visits",
                    data: data.series.total
                })
            }

            if (Array.isArray(data?.series?.users)) {
                series.push({
                    name: "Users",
                    data: data.series.users
                })
            }

            if (Array.isArray(data?.series?.routes)) {
                series.push({
                    name: "Routes",
                    data: data.series.routes
                })
            }

            if (params?.country_code && Array.isArray(data?.series?.cities)) {
                series.push({
                    name: "Cities",
                    data: data.series.cities
                })
            } else if (Array.isArray(data?.series?.countries)) {
                series.push({
                    name: "Countries",
                    data: data.series.countries
                })
            }

            setSeries(series)
        }, []),

        getHistory = useCallback((params) => {
            axios.get(config.dashboard.endpoints.history, {params})
                .then(res => {
                    setPeriods(Array.isArray(res?.data?.periods) ? res.data.periods : [])
                    handleResponseSeries(res.data, params)
                })
        }, [])

    useEffect(() => {
        getHistory(filters)
    }, [filters])

    return {
        periods,
        series
    }
}
