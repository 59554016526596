import wizard from "./components/Forms/Wizard";
import selectors from "./components/Selectors/Selectors";
import {CustomAlert} from "./react/Components/CustomAlerts";

(function() {
    wizard.render();

    $(".phone-digits").inputFilter(function(value) {
        return /^\d*$/.test(value);    // Allow digits only, using a RegExp
    },"Only digits allowed");

    selectors.render();

    $('.logout_link').on('click', function(e) {
        e.preventDefault();
        $('#logout_form').submit();
    })

    $('form.model-delete-form').on('submit', function(e) {
       e.preventDefault();
       const showAlert = $(this).attr('data-permanent-alert') == 1;
       const model = $(this).attr('data-model') ?? "item";
       if(showAlert) {
           CustomAlert(
               "Permanently delete product",
               `Are you sure you want to <strong>permanently delete</strong> this ${model}`,
               () => e.target.submit(),
               null,
               {
                   icon: 'question'
               }
           )
       } else {
           e.target.submit();
       }

    });
})();
