import React, {useEffect, useState} from "react";
import {CollectionHeader} from "./Partials/CollectionHeader";
import {ContainerLoader} from "../Components/ContainerLoader";
import {CollectionProducts} from "../CollectionProducts/CollectionProducts";
import {getCollection} from "../Services/CollectionService";
import {LayoutDataContainer} from "../Components/CustomForm/LayoutDataTable";
import {config} from "../config";

export const CollectionView = ({collectionId}) => {
    const [collection, setCollection] = useState(null);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getCollection(collectionId, setCollection, setError, setLoader);
    }, []);

    return (
        <div className="container">
            {collection &&
            <>
                <CollectionHeader collection={collection} setCollection={setCollection}/>

                <div className="row justify-content-center mt-2">

                    <div className="col-12">
                        <div className="card mg-b-10">
                            <div className="card-body">
                                <LayoutDataContainer
                                    inputs={config.collections.viewInputs ?? []}
                                    data={collection ?? {} }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <CollectionProducts collection={collection}/>

                <div className="row justify-content-center mt-2">

                    <div className="col-12">
                        <div className="card mg-b-20">
                            <div className="card-header">
                                <h6>
                                    Layout - {collection.layout.title}
                                </h6>
                            </div>
                            <div className="card-body">
                                <LayoutDataContainer
                                    inputs={collection.layout.inputs ?? []}
                                    data={collection.layout_data ?? {} }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }

            <ContainerLoader visible={loader}/>
        </div>
    )
}



