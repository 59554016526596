import {useCallback, useEffect, useState} from "react";
import {config} from "../../config";
import VisitsStats from "./VisitsStats";
import IpStats from "./IpStats";
import RouteStats from "./RouteStats";
import CountriesStats from "./CountriesStats";
import CitiesStats from "./CitiesStats";
import ISPsStats from "./ISPsStats";

export default function Stats({filters, onFilterChange}) {
    const [stats, setStats] = useState({}),
        getStats = useCallback((params) => {
            axios.get(config.dashboard.endpoints.stats, {params})
                .then(res => setStats(res.data))
        }, [])

    useEffect(() => {
        getStats(filters)
    }, [filters])

    return (
        <>
            <div className="col-xl-2 col-sm-3 col-6 mb-3">
                <VisitsStats filters={filters} count={stats?.total ?? 0} onFilterChange={onFilterChange}/>
            </div>

            <div className="col-xl-2 col-sm-3 col-6 mb-3">
                <IpStats filters={filters} count={stats?.users ?? 0} onFilterChange={onFilterChange}/>
            </div>

            <div className="col-xl-2 col-sm-3 col-6 mb-3">
                <RouteStats filters={filters} count={stats?.routes ?? 0} onFilterChange={onFilterChange}/>
            </div>

            <div className="col-xl-2 col-sm-3 col-6 mb-3">
                <CountriesStats filters={filters} count={stats?.countries ?? 0} onFilterChange={onFilterChange}/>
            </div>

            <div className="col-xl-2 col-sm-3 col-6 mb-3">
                <CitiesStats filters={filters} count={stats?.cities ?? 0} onFilterChange={onFilterChange}/>
            </div>

            <div className="col-xl-2 col-sm-3 col-6 mb-3">
                <ISPsStats filters={filters} count={stats?.isps ?? 0} onFilterChange={onFilterChange}/>
            </div>
        </>
    )
}
