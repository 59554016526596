export default class Organization {

    render() {
        let element = $('#organization-form');
        if (element) {
            this.addValidation(element);
            this.addSteps(element);
            this.addRegisterAsListeners();
        }
    }

    addValidation(element) {
        element.validate({
            errorPlacement: function errorPlacement(error, element) {
                if (element.attr("name") === "country") {
                    element.next().after(error); // this would append the label after all your checkboxes/labels (so the error-label will be the last element in <div class="controls"> )
                } else {
                    element.after(error);
                }
            },
            rules: {
                confirm: {
                    equalTo: "#password"
                }
            }
        });
    }

    addSteps(element) {
        element.steps({
            headerTag: 'h3',
            bodyTag: 'section',
            autoFocus: true,
            titleTemplate: '<span class="number">#index#<\/span> <span class="title">#title#<\/span>',
            onStepChanging: function (event, currentIndex, newIndex) {
                return true;
                element.validate().settings.ignore = ":disabled,:hidden";
                return element.valid();
            }
        });
    }

    addRegisterAsListeners() {
        let _this = this;
        $('#seller-checkbox').on('change', function () {
            _this.updateNeedsAndWantsComponents();
        });

        $('#buyer-checkbox').on('change', function () {
            _this.updateNeedsAndWantsComponents();
        });
    }

    updateNeedsAndWantsComponents() {
        let sellerSelected = $('#seller-checkbox').prop('checked');
        let buyerSelected = $('#buyer-checkbox').prop('checked');

        let headerTitle = 'Needs and Capabilities';
        if (sellerSelected && !buyerSelected) {
            headerTitle = 'Capabilities';
        } else if (buyerSelected && !sellerSelected) {
            headerTitle = 'Needs';
        }

        $('#organization-form-h-2').text(headerTitle);

        if (sellerSelected) {
            $('#capabilities-section').show();
        } else {
            $('#capabilities-section').hide();
        }

        if (buyerSelected) {
            $('#needs-section').show();
        } else {
            $('#needs-section').hide();
        }
    }
}
