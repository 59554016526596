import {ActionButtons} from "./ActionButtons";
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {CustomPagination} from "../../Components/CustomPagination";
import {ComponentLoader} from "../../Components/ComponentLoader";
import ApiService from "../../Services/ApiService";
import {config} from "../../config";
import {getSelectedIds} from "../../../helpers";


export const PurchaseLinkTable = forwardRef(({
                                                 onEdit,
                                                 filters,
                                                 onPageChange,
                                                 selectedOptions,
                                                 onItemSelected,
                                                 selectable = false,
                                                 multiple = false
                                             }, ref) => {

    const [purchaseLinks, setPurchaseLinks] = useState([]);
    const [metaData, setMetaData] = useState({});
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    const onPurchaseLinksLoaded = (data) => {
        setPurchaseLinks(data.data);
        setMetaData(data.metadata);
    }

    const api = useMemo(() => new ApiService(config.purchaseLinks.endpoints), []);
    const getPurchaseLinks = () => api.get(onPurchaseLinksLoaded, setError, setLoader, {...filters, selected: getSelectedIdsFilter()});
    useEffect(getPurchaseLinks, [filters])

    useImperativeHandle(ref, () => ({
        refreshTable() {
            getPurchaseLinks();
        }
    }));

    const isSelected = (item) => {
        if (multiple && Array.isArray(selectedOptions)) {
            return !!selectedOptions.find((i) => item.id === i.id);
        } else if (!multiple && selectedOptions) {
            return selectedOptions.id === item.id;
        }

        return false;
    }

    const getSelectedIdsFilter = () => {
        if (selectable) {
            const ids = getSelectedIds(selectedOptions, multiple);
            return multiple ? ids : [ids];
        }

        return null;
    }

    return (
        <>
            <div className={"mt-3 mb-3 p-4 bg-white rounded-5 position-relative"}>
                <div className="row justify-content-center mt-3">
                    <div className={"col-12 table-responsive"}>
                        <table className={"table table-bordered"}>
                            <thead>
                            <tr>
                                <th>No.</th>
                                <th>UUID</th>
                                <th>Name</th>
                                <th>Seller</th>
                                <th>Price</th>
                                <th>URL</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                (Array.isArray(purchaseLinks) ? purchaseLinks : []).map((item, index) => (
                                    <tr key={index}>
                                        <td className={selectable ? "wd-100" : "wd-50"}>
                                            {selectable ?
                                                <label className="ckbox">
                                                    <input type="checkbox" checked={isSelected(item)} onChange={() => onItemSelected(item, isSelected(item))}/>
                                                    <span>{(metaData.from ?? 0) + index}</span>
                                                </label>
                                                :
                                                <>{(metaData.from ?? 0) + index}</>
                                            }
                                        </td>
                                        <td>{item.uuid}</td>
                                        <td>{item.name}</td>
                                        <td>{item.seller}</td>
                                        <td>{item.price}</td>
                                        <td className={"wd-400"}>
                                            <a className={"link"} href={item.url} target={"_blank"}>
                                                {item.url} {item.isRedirected ? '(Redirected)' : ''}
                                            </a>
                                        </td>
                                        <td>
                                            <span
                                                className={`badge badge-pill badge-${item.status === 'Active' ? 'primary' : 'danger'}`}>
                                                {item.status}
                                            </span>
                                        </td>
                                        <td>
                                            <ActionButtons
                                                api={api}
                                                metaTag={item}
                                                onEdit={() => onEdit(item)}
                                                purchaseLink={item}
                                                onUpdated={getPurchaseLinks}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        <span className={"text-muted"}>
                            Showing <strong>{metaData.from ?? 0}</strong>-<strong>{metaData.to ?? 0}</strong> of {metaData.total ?? 0} records
                        </span>
                    </div>
                </div>

                <ComponentLoader visible={loader}/>
            </div>

            <div className={"row"}>
                <CustomPagination
                    current={filters?.page ?? 1}
                    onPageChange={onPageChange}
                    perPage={filters?.perPage ?? metaData?.perPage ?? 10}
                    total={metaData.total ?? 0}
                />
            </div>
        </>
    )
})
