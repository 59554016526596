import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";

export const CustomPagination = (props) => {
    const [list, setList] = useState([]);

    const onPageChange = (page) => {
        if(typeof props.onPageChange === 'function') {
            props.onPageChange(page);
        }
    }

    useEffect(() => {
        const totalPages = Math.ceil(props.total / (props.perPage ?? 10));
        const tempList = [];

        const totalPrev = 6 - (props.current > 6 ? 6 : (props.current - 1));
        const totalAfter = 6 - (props.current < 95 ? 6 : (totalPages - props.current));

        if(props.current - 6 > 0) {
            tempList.push(
                <li className="page-item" key={tempList.length}>
                    <a aria-label="Last" className="page-link" href="#" onClick={() => onPageChange(1)}>
                        <FeatherIcon icon={'chevrons-left'}/>
                    </a>
                </li>
               );
        }

        for(let i = props.current - (5 + totalAfter); i < props.current; i++) {
            if(i > 0) {
                tempList.push(
                    <li className="page-item" key={tempList.length}>
                        <a className="page-link" href="#" onClick={() => onPageChange(i)}>
                            {i}
                        </a>
                    </li>
                )
            }
        }

        tempList.push(
            <li className="page-item active"  key={tempList.length}>
                <a className="page-link" href="#">{props.current}</a>
            </li>
        )

        let lastItem = false;

        for(let i = props.current + 1; i < props.current + (6 + totalPrev); i++) {
            if(i <= totalPages) {
                lastItem = i !== totalPages;
                tempList.push(
                    <li className="page-item"  key={tempList.length}>
                        <a className="page-link" href="#" onClick={() => onPageChange(i)}>
                            {i}
                        </a>
                    </li>
                )
            }
        }

        if(lastItem) {
            tempList.push(
                <li className="page-item"  key={tempList.length}>
                    <a className="page-link" href="#" onClick={() => onPageChange(totalPages)}>
                        {(props.current + 6 === totalPages) ?
                            totalPages :
                            <FeatherIcon icon={'chevrons-right'}/>
                        }

                    </a>
                </li>
            );
        }


        setList(tempList);

    }, [props.current, props.total, props.perPage])

    return (
        <div className={"col-12"}>
            <div className={"card mg-b-20"}>
                <div className={"card-body text-center"}>
                    <ul className="pagination mg-b-0 page-0 ">
                        {list}
                    </ul>
                </div>
            </div>
        </div>
    )
}
