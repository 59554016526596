import FeatherIcon from "feather-icons-react";
import React from "react";

export const SeoMetaTagsHeader = ({onAddNewClick}) => {
    return (
        <div className="main-content-header p-4 bg-white mt-2 rounded-5">
            <div>
                <h6 className="main-content-title tx-14 mg-b-5 mg-t-3">SEO Meta Tags</h6>
                <p className="main-content-text tx-13 mg-b-0">
                    You can add or edit SEO meta tags here.
                </p>
            </div>
            <div className="main-content-header-right sub-headerstyle">
                <button className="btn btn-primary rounded-5 d-flex align-items-center justify-content-center"
                        onClick={onAddNewClick}>
                    <FeatherIcon icon={"plus"} size={18}/> <span className={"ml-2"}>Add new meta tag</span>
                </button>
            </div>
        </div>
    )
}
