import $ from 'jquery';

window.$ = window.jQuery = $;

require('./bootstrap');
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min';
require('./components/sidebar');
require('../vendors/jquery.validate/jquery.validate.min');
require('../vendors/jquery.steps/jquery.steps.min');
require('./components/inputFilter');
require('./components/sidemenu');
require ('./components/hormenu');
require('select2');
require('./theme');
require('./custom');
require('./react/react');
