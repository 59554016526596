import React from "react";
import {CustomSelect} from "./Inputs/CustomSelect";
import {CustomTextArea} from "./Inputs/CustomTextArea";
import {CustomImageSelector} from "./Inputs/CustomImageSelector";
import {CustomLayoutSelector} from "./Inputs/CustomLayoutSelector";
import {CustomTextInput} from "./Inputs/CustomTextInput";
import {CustomFormRepeater} from "./Inputs/CustomFormRepeater";
import {getObjValue} from "../../../helpers";
import {CustomRatingInput} from "./Inputs/CustomRatingInput";
import {CustomPurchaseLinkInput} from "./Inputs/CustomPurchaseLink";
import {CustomProsAndCons} from "./Inputs/CustomProsAndCons";

export const CustomInput = (props) => {
    const {id = null} = props.input;

    switch (props.input.type) {
        case 'select':
            return <CustomSelect
                input={props.input}
                defaultValue={props.defaultValue ? (props.defaultValue[id] ?? "") : ""}
                onChange={props.onChange}
                onReady={props.onReady}/>
        case 'textarea':
            return <CustomTextArea
                input={props.input}
                defaultValue={props.defaultValue ? (getObjValue(props.defaultValue, id) ?? "") : ""}
                onChange={props.onChange}
                onReady={props.onReady}/>
        case 'image':
            return <CustomImageSelector
                input={props.input}
                defaultValue={props.defaultValue ? (getObjValue(props.defaultValue, id) ?? "") : ""}
                onChange={props.onChange}
                mutliple={false}
                onReady={props.onReady}/>
        case 'multiple_images':
            return <CustomImageSelector
                input={props.input}
                defaultValue={props.defaultValue ? (getObjValue(props.defaultValue, id) ?? "") : ""}
                onChange={props.onChange}
                multiple={true}
                onReady={props.onReady}/>
        case 'layout_selector':
            return <CustomLayoutSelector
                input={props.input}
                defaultValue={props.defaultValue ? (props.defaultValue[id] ?? "") : ""}
                dataDefaultValues={props.defaultValue ? (props.defaultValue[props.input.formDataKey] ?? null) : null}
                onChange={props.onChange}
                onReady={props.onReady}/>
        case 'form_repeater':
            return <CustomFormRepeater
                input={props.input}
                defaultValue={props.defaultValue ? (props.defaultValue[id] ?? null) : null}
                onChange={props.onChange}
                onReady={props.onReady}/>
        case 'rating':
            return <CustomRatingInput
                input={props.input}
                defaultValue={props.defaultValue ? (getObjValue(props.defaultValue, id) ?? "") : ""}
                onChange={props.onChange}
                onReady={props.onReady}/>
        case 'purchase_link':
            return <CustomPurchaseLinkInput
                input={props.input}
                defaultValue={props.defaultValue ? (getObjValue(props.defaultValue, id) ?? "") : ""}
                onChange={props.onChange}
                multiple={false}
                onReady={props.onReady}/>
        case 'purchase_links':
            return <CustomPurchaseLinkInput
                input={props.input}
                defaultValue={props.defaultValue ? (getObjValue(props.defaultValue, id) ?? "") : ""}
                onChange={props.onChange}
                multiple={true}
                onReady={props.onReady}/>
        case 'pros_and_cons':
            return <CustomProsAndCons
                input={props.input}
                defaultValue={props.defaultValue ? (getObjValue(props.defaultValue, id) ?? "") : ""}
                onChange={props.onChange}
                onReady={props.onReady}/>
        default:
            return <CustomTextInput
                ref={props.input.id === 'textual_id' ? (props.textualIdRef ?? null) : null}
                input={props.input}
                defaultValue={props.defaultValue ? (getObjValue(props.defaultValue, id) ?? "") : ""}
                onChange={props.onChange}
                onReady={props.onReady}/>
    }
}
