export const config = {
    dashboard: {
      endpoints: {
          visits: 'dashboard/visits',
          stats: '/dashboard/stats',
          history: '/dashboard/history',
          lookup: '/dashboard/lookup'
      }
    },
    categories: {
        endpoints: {
            index: '/categories',
            getLayouts: '/layouts/categories',
            get: '/categories/get',
            store: '/categories',
            find: id => `/categories/${id}`,
            update: id => `/categories/${id}`,
            delete: id => `/categories/${id}`
        },
        customFormInputs(layoutsEndpoint, categoriesEndpoint, categoriesFilter) {
            return {
                inputs: [
                    {
                        id: "name",
                        type: "text",
                        title: "Name:",
                        description: "Enter category name"
                    },
                    {
                        id: "textual_id",
                        type: "text",
                        title: "Textual ID:",
                        description: "Enter textual ID"
                    },
                    {
                        id: "parent_id",
                        type: "select",
                        title: "Parent:",
                        description: "Select parent category",
                        dataSource: {
                            endpoint: categoriesEndpoint,
                            filter: categoriesFilter,
                        }
                    },
                    {
                        id: "image_id",
                        type: 'image',
                        title: 'Thumbnail Image',
                        description: "Please select/upload a thumbnail image"
                    },
                    {
                        id: "menu_display_type",
                        type: 'select',
                        title: 'Menu display type',
                        description: 'Select how the category will be displayed in navigation menu',
                        options: [
                            {
                                "value": "hide",
                                "label": "Hidden"
                            },
                            {
                                "value": "standard",
                                "label": "Standard"
                            },
                            {
                                "value": "posts_only",
                                "label": "Posts only"
                            },
                            {
                                "value": "subcategories_only",
                                "label": "Sub-categories only"
                            },
                            {
                                "value": "posts_and_subcategories",
                                "label": "Posts and sub-categories"
                            },
                        ],
                    },
                    {
                        id: "layout",
                        type: "layout_selector",
                        formDataKey: "layout_data",
                        title: "Layout:",
                        description: "Select a layout",
                        endpoint: layoutsEndpoint
                    },
                ]
            }
        }
    },
    collections: {
        endpoints: {
            index: '/collection',
            getLayouts: '/layouts/collections',
            get: '/collection/get',
            store: '/collection',
            find: id => `/collection/get/${id}`,
            update: id => `/collection/${id}`,
            create: id => `/collection/${id}/create`,
            edit: id => `/collection/${id}/edit`,
            show: id => `/collection/${id}`,
            delete: id => `/collection/${id}`,
            restore: id => `/collection/${id}/restore`,
            getAllowedStatus: id => `/collection/${id}/allowed-status`,
            updateStatus: id => `/collection/${id}/status-update`
        },
        customFormInputs(categoriesEndpoint, layoutsEndpoint) {
            return (
                {
                    inputs: [
                        {
                            id: "name",
                            type: "text",
                            title: "Collection name:",
                            description: "Enter collection name"
                        },
                        {
                            id: "textual_id",
                            type: "text",
                            title: "Textual ID:",
                            description: "Enter textual ID"
                        },
                        {
                            id: "sub_title",
                            type: "text",
                            title: "Sub title",
                            description: "Please write sub title"
                        },
                        {
                            id: "price_tag",
                            type: "text",
                            title: "Price tag",
                            description: "e.g. £19.99 - £49.99"
                        },
                        {
                            id: "category_id",
                            type: "select",
                            title: "Category:",
                            description: "Select category",
                            dataSource: {
                                endpoint: categoriesEndpoint
                            }
                        },
                        {
                            id: "image_id",
                            type: 'image',
                            title: 'Thumbnail Image',
                            description: "Please select/upload a thumbnail image"
                        },
                        {
                            id: "menu_display_type",
                            type: 'select',
                            title: 'Menu display type',
                            description: 'Select how the collection will be displayed in navigation menu',
                            options: [
                                {
                                    "value": 'hidden',
                                    "label": "Hidden"
                                },
                                {
                                    "value": 'visible',
                                    "label": "Visible"
                                }
                            ],
                        },
                        {
                            id: "layout",
                            type: "layout_selector",
                            formDataKey: "layout_data",
                            title: "Layout:",
                            description: "Select a layout",
                            endpoint: layoutsEndpoint
                        },
                    ]
                }
            )
        },
        viewInputs: [
            {
                id: "sub_title",
                type: "text",
                title: "Sub title",
            },
            {
                id: "price_tag",
                type: "text",
                title: "Price tag",
            },
            {
                id: "image",
                type: 'image',
                title: 'Thumbnail Image',
            },
            {
                id: "menu_display_type",
                type: 'text',
                title: 'Menu display type',
            },
        ]
    },
    collectionProducts: {
        endpoints: {
            index: collection => `/collection/${collection}`,
            get: collection => `/collection/${collection}/products`,
            store: collection => `/collection/${collection}/products`,
            find: (collection, id) => `/collection/${collection}/products/${id}`,
            update: (collection, id) => `/collection/${collection}/products/${id}`,
            delete: (collection, id) => `/collection/${collection}/products/${id}`,
            restore: (collection, id) => `/collection/${collection}/products/${id}/restore`,
            sort: collection => `/collection/${collection}/products/sort`,
        },
        viewModal: {
            classNames: {
                default: 'col-md-4',
                images: 'col-md-4 slide-show-images-container'
            }
        }
    },
    products: {
        endpoints: {
            index: `/products`,
            getLayouts: '/layouts/products',
            store: `/products`,
            find: product => `/products/${product}/edit`,
            update: product => `/products/${product}`,
            delete: product => `/products/${product}`,
            restore: product => `/products/${product}/restore`,
        },
        customFormInputs(layoutsEndpoint, categoriesEndpoint = null) {
            const result = {
                inputs: [
                    {
                        id: "name",
                        type: "text",
                        title: "Product name:",
                        description: "Enter product name"
                    },
                    {
                        id: "details",
                        type: "text",
                        title: "Product details:",
                        description: "Enter product details"
                    },
                    {
                        id: "textual_id",
                        type: "text",
                        title: "Textual ID:",
                        description: "Enter textual ID"
                    },
                    {
                        id: "images",
                        type: "multiple_images",
                        title: "Images:",
                        description: "Select images"
                    }
                ]
            }

            if (categoriesEndpoint) {
                result.inputs.push({
                    id: "category_id",
                    type: "select",
                    title: "Category:",
                    description: "Select category",
                    dataSource: {
                        endpoint: categoriesEndpoint
                    }
                })
            }

            result.inputs.push({
                id: "layout",
                type: "layout_selector",
                formDataKey: "layout_data",
                title: "Layout:",
                description: "Select a layout",
                endpoint: layoutsEndpoint
            });

            return result;
        }
    },
    uploadedImages: {
        endpoints: {
            store: '/upload-image',
            get: '/upload-image/get',
            getSelected: '/upload-image/get/selected',
            find: id => `/upload-image/${id}`,
            delete: id => `/upload-image/${id}`,
            criteria: '/upload-image/criteria'
        }
    },
    homeSlider: {
        endpoints: {
            index: `/home-slider`,
            getLayouts: '/layouts/home_slider',
            store: `/home-slider`,
            get: `/home-slider/get`,
            sort: `/home-slider/sort`,
            find: id => `/home-slider/${id}`,
            update: id => `/home-slider/${id}`,
            delete: id => `/home-slider/${id}`,
            restore: id => `/home-slider/${id}/restore`,
        },
        customFormInputs(layoutsEndpoint) {
            return {
                inputs: [
                    {
                        id: "title",
                        type: "text",
                        title: "Slider Title:",
                        description: "Enter slider title"
                    },
                    {
                        id: "image_id",
                        type: "image",
                        title: "Image:",
                        description: "Select image"
                    },
                    {
                        id: "layout",
                        type: "layout_selector",
                        formDataKey: "layout_data",
                        title: "Layout:",
                        description: "Select a layout",
                        endpoint: layoutsEndpoint
                    },
                ]
            }
        }
    },
    promotions: {
        endpoints: {
            index: `/promotions`,
            getLayouts: '/layouts/promotions',
            store: `/promotions`,
            get: `/promotions/get`,
            sort: `/promotions/sort`,
            find: id => `/promotions/${id}`,
            update: id => `/promotions/${id}`,
            delete: id => `/promotions/${id}`,
            restore: id => `/promotions/${id}/restore`,
        },
        pageSelector: {
            endpoints: {
                get: page => `/promotions/config/pages/${page}`,
                patch: page => `/promotions/config/pages/${page}`
            },
            pages: [
                {
                    label: 'Home',
                    value: 'home',
                },
                {
                    label: 'Collections',
                    value: 'collection',
                },
                {
                    label: 'Categories',
                    value: 'category'
                },
                {
                    label: 'Products',
                    value: 'product'
                }
            ],
            models: {
                collection: {
                    endpoint: '/collection/get',
                    labelKey: 'name',
                    valueKey: 'id'
                },
                category: {
                    endpoint: '/categories/get',
                    labelKey: 'name',
                    valueKey: 'id'

                },
                product: {
                    endpoint: '/products/get',
                    labelKey: 'name',
                    valueKey: 'id'
                },
            }
        },
        customFormInputs(layoutsEndpoint) {
            return {
                inputs: [
                    {
                        id: "title",
                        type: "text",
                        title: "Promotion Title:",
                        description: "Enter promotion title"
                    },
                    {
                        id: "layout",
                        type: "layout_selector",
                        formDataKey: "layout_data",
                        title: "Layout:",
                        description: "Select a layout",
                        endpoint: layoutsEndpoint
                    },
                ]
            }
        }
    },
    seoMetaTags: {
        endpoints: {
            index: `/seo_meta_tags`,
            store: `/seo_meta_tags`,
            get: `/seo_meta_tags/get`,
            find: id => `/seo_meta_tags/${id}`,
            update: id => `/seo_meta_tags/${id}`,
            delete: id => `/seo_meta_tags/${id}`,
            restore: id => `/seo_meta_tags/${id}/restore`,
        },
        pageSelector: {
            pages: [
                {
                    label: 'Home',
                    value: 'home',
                },
                {
                    label: 'Collections',
                    value: 'collection',
                },
                {
                    label: 'Categories',
                    value: 'category'
                },
                {
                    label: 'Products',
                    value: 'product'
                },
                {
                    label: 'About',
                    value: 'about'
                },
                {
                    label: 'Contact',
                    value: 'contact'
                }
            ],
            models: {
                collection: {
                    endpoint: '/collection/get',
                    labelKey: 'name',
                    valueKey: 'id'
                },
                category: {
                    endpoint: '/categories/get',
                    labelKey: 'name',
                    valueKey: 'id'
                },
                product: {
                    endpoint: '/products/get',
                    labelKey: 'name',
                    valueKey: 'id'
                },
            }
        },
        customFormInputs(pages, modelDataSource) {
            return {
                inputs: [
                    {
                        id: "page",
                        type: "select",
                        options: pages,
                        title: "Page:",
                        description: "Select page",
                    },
                    {
                        id: "model_id",
                        type: "select",
                        dataSource: modelDataSource,
                        options: [],
                        title: "Model:",
                        description: "Select model",
                    },
                    {
                        id: "name",
                        type: "text",
                        title: "Tag name:",
                        description: "Please enter tag name",
                    },
                    {
                        id: "value",
                        type: "textarea",
                        title: "Tag content:",
                        description: "Please enter tag content",
                        showCounts: true
                    }
                ]
            }
        }
    },
    purchaseLinks: {
        endpoints: {
            index: `/purchase_links`,
            store: `/purchase_links`,
            get: `/purchase_links/get`,
            find: id => `/purchase_links/${id}`,
            update: id => `/purchase_links/${id}`,
            updateStatus: id => `/purchase_links/${id}/update_status`,
        },
        customFormInputs(sellersEndpoint) {
            return {
                inputs: [
                    {
                        id: "name",
                        type: "text",
                        title: "Name:",
                        description: "Enter label/name for this purchase link"
                    },
                    {
                        id: "seller_id",
                        type: "select",
                        dataSource: {
                            endpoint: sellersEndpoint,
                            labelKey: 'name',
                            valueKey: 'id'
                        },
                        title: "Seller:",
                        description: "Select a seller",
                    },
                    {
                        id: "price",
                        type: "text",
                        title: "Price:",
                        description: "Enter the price e.g. 19.99",
                    },
                    {
                        id: "currency",
                        type: "text",
                        title: "Currency:",
                        description: "Enter currency e.g. £",
                    },
                    {
                        id: "url",
                        type: "text",
                        title: "URL:",
                        description: "Enter purchase link URL",
                        divCustomClass: "col-md-12"
                    }
                ]
            }
        }
    },
    sellers: {
        endpoints: {
            index: `purchase_links`,
            store: `/sellers`,
            get: `/sellers/get`,
        },
        customFormInputs: {
            inputs: [
                {
                    id: "name",
                    type: "text",
                    title: "Name:",
                    description: "e.g. Amazon, eBay",
                },
                {
                    id: "logo",
                    type: "image",
                    title: "Logo:",
                    description: "Select/upload logo",
                }
            ]
        }
    },
    layoutDataContainer: {
        classNamesByType: {
            textarea: 'col-12',
            multiple_images: 'col-12',
            form_repeater: 'col-12',
            image: 'col-12',
            purchase_links: 'col-12',
            pros_and_cons: 'col-12'
        }
    },
    customAttributeRepeaterForm({
                                    id = 'custom_attributes',
                                    title = "Custom Attributes",
                                    description = "You can add custom attributes for this layout",
                                    min = 0
                                }) {
        return {
            id: id,
            type: 'form_repeater',
            title: title,
            description: description,
            min: min,
            inputs: [
                {
                    id: "id",
                    type: "text",
                    title: "ID:",
                    description: "Enter attribute id",
                    divCustomClasses: "col-md-4 mg-b-10"
                },
                {
                    id: "title",
                    type: "text",
                    title: "Title:",
                    description: "Enter attribute title",
                    divCustomClasses: "col-md-4 mg-b-10"
                },
                {
                    id: "type",
                    type: "select",
                    title: "Attribute type:",
                    options: [
                        {
                            value: "text",
                            label: "Text"
                        },
                        {
                            value: "image",
                            label: "Image"
                        },
                        {
                            value: "textarea",
                            label: "Textarea"
                        },
                        {
                            value: "rating",
                            label: "Rating"
                        },
                        {
                            value: "purchase_link",
                            label: "Purchase link"
                        }
                    ],
                    description: "Select an option",
                    divCustomClasses: "col-md-4 mg-b-10"
                }
            ]
        }
    }
}
