import {CustomModal} from "../../Components/CustomModal";
import React, {useState} from "react";
import {CustomRadioInput} from "../../Components/CustomForm/Inputs/CustomRadioInput";
import {CustomTextInput} from "../../Components/CustomForm/Inputs/CustomTextInput";
import {ContainerLoader} from "../../Components/ContainerLoader";

export const PurchaseLinkStatusUpdate = ({purchaseLink, visible, onHide, api, onUpdated}) => {
    const [loader, setLoader] = useState(false);
    const [formData] = useState({});

    const onChange = (key, value) => {
        formData[key] = value;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        api.put(formData, () => {
            onUpdated();
            onHide();
        }, null, setLoader, 'updateStatus', purchaseLink)
    }

    return (
        <CustomModal
            visible={visible}
            className={"modal-dialog"}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h6 className="modal-title">
                        Update purchase link status - {purchaseLink?.name ?? ""}
                    </h6>
                    <button aria-label="Close" className="close" type="button" onClick={onHide}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className={"card"} style={{backgroundColor: "#ffffff3d"}}>
                        <div className={"card-body"}>
                            <form action={"#"} method={"post"} onSubmit={onSubmit}>
                                <div className={"row"}>
                                    <CustomRadioInput
                                        defaultValue={purchaseLink.status.toLowerCase()}
                                        onChange={onChange}
                                        input={{
                                            id: 'status',
                                            title: 'Status',
                                            options: [
                                                {value: 'active', label: 'Active'},
                                                {value: 'disabled', label: 'Disabled'},
                                            ],
                                            divCustomClasses: 'col-12',
                                        }}
                                    />

                                    <CustomTextInput
                                        defaultValue={purchaseLink.isRedirected ? purchaseLink.url : ''}
                                        onChange={onChange}
                                        input={{
                                            id: 'redirectUrl',
                                            title: 'Redirect URL',
                                            description: 'Enter redirect URL if needed',
                                            divCustomClasses: 'col-12 mt-3',
                                        }}
                                    />

                                    <div className={"col-12 d-flex justify-content-end mt-5"}>
                                        <button type={"submit"} className={"btn btn-primary rounded-5"}> Save </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <ContainerLoader visible={loader} />
        </CustomModal>
    )
}
