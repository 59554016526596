import React, { useRef, useState } from 'react';
import axios from 'axios';
import {LayoutInputs} from "./LayoutInputs";
import {ContainerLoader} from "../ContainerLoader";
import {IconButton} from "../IconButton";
import {CustomAlert} from "../CustomAlerts";
import {replaceSpecialCharsWithDash, setObjValue} from "../../../helpers";


export const CustomForm = (props) => {
    const [loader, setLoader] = useState(false);
    const [defaultValue, setDefaultValues] = useState({});
    const [error, setError] = useState(null);
    const textualIdRef = useRef(null);
    const [formData] = useState({data: {}});

    const onChange = (key, value) => {
        formData.data = setObjValue(formData.data, key, value)

        if (props.textualIdGeneratorKey && key === props.textualIdGeneratorKey && textualIdRef?.current?.setInputValue) {
            textualIdRef.current.setInputValue(replaceSpecialCharsWithDash(value));
            formData.data = setObjValue(formData.data, 'textual_id', replaceSpecialCharsWithDash(value));
        }

        if (typeof props.onChange === 'function') {
            props.onChange(key, value);
        }
    }

    const getEditModel = () => {

        if (props.editModel && props.endpoints.edit) {
            setLoader(true)
            axios.get(props.endpoints.edit)
                .then((res) => {
                    setDefaultValues(res.data);
                    formData.data = res.data;
                })
                .catch((err) => {
                    setError(err?.response?.data?.message ?? 'Error Occurred');
                })
                .finally(() => {
                    setLoader(false);
                })
        }
    }

    const onSubmit = () => {
        const successFn = () => typeof props.onSuccess === 'function' ? props.onSuccess() : window.location.href = props.endpoints.index;
        const errorFn = (err) => {
            setLoader(false);
            CustomAlert(
                "Error occurred",
                err?.response?.data?.message ?? "Error occurred, please try again",
                null,
                null,
                {
                    confirmButtonText: "Ok",
                    denyButton: false,
                    icon: "error"
                }
            );
        };

        if (props.editModel && props.endpoints.update) {
            axios.patch(props.endpoints.update, formData.data).then(successFn).catch(errorFn);
        } else {
            axios.post(props.endpoints.store, formData.data).then(successFn).catch(errorFn);
        }
    }

    return error ?
        (
            <div className={"row"}>
                <div className={"col-12"}>
                    <p className={"text-center w-100 tx-18 text-danger"}>Error occurred, please reload the page</p>
                </div>
            </div>
        )
        :
        (
            <React.Fragment>
                <div className={"row"}>
                    <LayoutInputs
                        layout={props.layout}
                        onChange={onChange}
                        defaultValue={defaultValue}
                        onReady={getEditModel}
                        textualIdRef={textualIdRef}
                    />
                </div>

                <div className={"row mg-t-10"}>
                    <div className={"col-md-12 d-flex justify-content-end"}>
                        <IconButton
                            text={"Save"}
                            icon={"save"}
                            onClick={onSubmit}
                            loader={loader}
                        />
                    </div>
                </div>

                <ContainerLoader visible={loader}/>
            </React.Fragment>
        )
}

