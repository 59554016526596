import React, {useEffect, useRef, useState} from "react";
import {getPromotions, onPromotionDragEnd, saveSortedPromotions} from "../Services/PromotionService";
import {ContainerLoader} from "../Components/ContainerLoader";
import {PromotionsHeader} from "./Partials/PromotionsHeader";
import {areIdenticalArrays} from "../../helpers";
import {PromotionItem} from "./Partials/PromotionItem";
import {PromotionForm} from "./Partials/PromotionForm";
import {PageSettingsModal} from "./PageSettings/PageSettingsModal";

export const Promotions = () => {
    const [promotions, setPromotions] = useState([]);
    const [sortablePromotions, setSortablePromotions] = useState([]);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [showFormModal, setShowFormModal] = useState(false);
    const [editablePromotion, setEditablePromotion] = useState(null);
    const [pageSettingsModal, setPageSettingsModal] = useState(false);

    const getPromotionsSuccess = (promotions) => {
        setPromotions(promotions);
        setSortablePromotions(promotions);
    }

    useEffect(() => getPromotions(getPromotionsSuccess, setError, setLoader), []);

    const draggingItemRef = useRef();
    const placingDraggedItemRef = useRef();

    const onShowFormModal = () => setShowFormModal(true);
    const onDragStart = index => draggingItemRef.current = index;
    const onDragOver = index => placingDraggedItemRef.current = index;
    const onDragEnd = () => setSortablePromotions(currentPromotions => onPromotionDragEnd(currentPromotions, draggingItemRef.current, placingDraggedItemRef.current))

    const onResetSorting = () => setSortablePromotions(() => [...promotions]);
    const onSaveSorting = () => saveSortedPromotions(sortablePromotions, getPromotionsSuccess, setError, setLoader);

    const onEdit = (item) => {
        setEditablePromotion(item);
        setShowFormModal(true);
    }

    const onHideForm = () => {
        setEditablePromotion(null);
        setShowFormModal(false);
    }


    return (
        <div>
            <PromotionsHeader
                sortingButtons={!areIdenticalArrays(promotions, sortablePromotions)}
                setHomeSliders={getPromotionsSuccess}
                onResetSorting={onResetSorting}
                onSaveSorting={onSaveSorting}
                onAddNewClick={onShowFormModal}
                onShowPageSettings={() => setPageSettingsModal(true)}
            />

            <div className="row justify-content-center mt-3">
                {
                    sortablePromotions.map((item, index) => (
                        <PromotionItem
                            key={index}
                            item={item}
                            onDragStart={() => onDragStart(index)}
                            onDragOver={() => onDragOver(index)}
                            onDragEnd={() => onDragEnd()}
                            setPromotions={getPromotionsSuccess}
                            onEdit={() => onEdit(item)}
                        />
                    ))
                }
            </div>

            <PromotionForm
                visible={showFormModal}
                onHide={onHideForm}
                promotion={editablePromotion}
            />

            <PageSettingsModal
              visible={pageSettingsModal}
              promotions={promotions}
              hideModal={() => setPageSettingsModal(false)}
            />

            <ContainerLoader visible={loader}/>
        </div>
    );
}
