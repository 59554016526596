import FeatherIcon from "feather-icons-react";
import React from "react";

export const PurchaseLinksHeader = ({onStateUpdate, formVisible = false}) => {
    const onNewSeller= (e) => {
        e.preventDefault();
        onStateUpdate({purchaseLinkForm: false, sellerForm: true});
    }

    const onNewPurchaseLink = (e) => {
        e.preventDefault();
        onStateUpdate({purchaseLinkForm: true, sellerForm: false});
    }

    const onCloseForms = (e) => {
        e.preventDefault();
        onStateUpdate({purchaseLinkForm: false, sellerForm: false});
    }

    return (
        <div className="main-content-header p-4 bg-white mt-2 rounded-5">
            <div>
                <h6 className="main-content-title tx-14 mg-b-5 mg-t-3">Purchase Links</h6>
                <p className="main-content-text tx-13 mg-b-0">
                    You can add, edit, and manage Purchase Links here.
                </p>
            </div>
            <div className="main-content-header-right sub-headerstyle">


                <div className="">
                    <button className="btn btn-primary rounded-5 d-flex align-items-center justify-content-center"
                            data-toggle="dropdown">
                        <FeatherIcon icon={"plus"} size={18}/>
                        <span className={"ml-2"}>Add new</span>
                        <FeatherIcon className={"ml-2"} style={{marginRight: "-2px"}} icon={"chevron-down"} size={18}/>
                    </button>

                    <div  className="dropdown-menu tx-13">
                        <a className="dropdown-item" href="#" onClick={onNewPurchaseLink}>Add new purchase link</a>
                        <a className="dropdown-item" href="#" onClick={onNewSeller}>Add new seller</a>
                        { formVisible ?
                            <a className="dropdown-item" href="#" onClick={onCloseForms}>Close form</a>
                            :
                            <></>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
