import Organization from "./Organization";

class Wizard
{
    render() {
        this.headerTemplate = '<span class="number">#index#<\/span> <span class="title">#title#<\/span>';
        let organization = new Organization();
        organization.render();
    }

    organizationTypeListeners() {
        $('#')
    }
}

let wizard = new Wizard();
export default wizard;
