import ReactDOM from 'react-dom/client';
import UploadedImages from "./UploadedImages/UploadedImages";
import {CategoriesForm} from "./Categories/CategoriesForm";
import {CollectionForm} from "./Collections/CollectionForm";
import {CollectionView} from "./Collections/CollectionView";
import {ProductsForm} from "./Products/ProductsForm";
import {HomeSlider} from "./HomeSlider/HomeSlider";
import {Promotions} from "./Promotions/Promotions";
import {SeoMetaTags} from "./SeoMetaTags/SeoMetaTags";
import {PurchaseLinks} from "./PurchaseLinks/PurchaseLinks";
import Dashboard from "./Dashboard/Dashboard";

//Dashboard
if (document.getElementById('dashboard_container')) {
    const root = ReactDOM.createRoot(document.getElementById('dashboard_container'));
    root.render(
        <Dashboard/>
    )
}

//Category
if (document.getElementById('new_category_container')) {
    const root = ReactDOM.createRoot(document.getElementById('new_category_container'));
    root.render(
        <CategoriesForm/>
    )
}

if (document.getElementById('edit_category_container')) {
    const root = ReactDOM.createRoot(document.getElementById('edit_category_container'));
    const editModel = document.getElementById('edit_category_container').getAttribute('data-id');
    if(editModel) {
        root.render(
            <CategoriesForm
                editModel={editModel}
            />
        )
    }
}


//Collections
if (document.getElementById('new_collection_container')) {
    const root = ReactDOM.createRoot(document.getElementById('new_collection_container'));
    root.render(
        <CollectionForm />
    )
}

if (document.getElementById('edit_collection_container')) {
    const root = ReactDOM.createRoot(document.getElementById('edit_collection_container'));
    const modelId = document.getElementById('edit_collection_container').getAttribute('data-id');
    if(modelId) {
        root.render(
            <CollectionForm
                editModel={modelId}
            />
        )
    }
}

if (document.getElementById('collection-view-container')) {
    const root = ReactDOM.createRoot(document.getElementById('collection-view-container'));
    const collectionId = document.getElementById('collection-view-container').getAttribute('data-collection');

    root.render(
        <CollectionView
            collectionId={collectionId}
        />
    )
}


// Products
if (document.getElementById('new_product_container')) {
    const root = ReactDOM.createRoot(document.getElementById('new_product_container'));
    root.render(
        <ProductsForm />
    )
}

if (document.getElementById('edit_product_container')) {
    const root = ReactDOM.createRoot(document.getElementById('edit_product_container'));
    const modelId = document.getElementById('edit_product_container').getAttribute('data-id');
    if(modelId) {
        root.render(
            <ProductsForm
                editModel={modelId}
            />
        )
    }
}

// Home Slider
if (document.getElementById('home_slider_container')) {
    const root = ReactDOM.createRoot(document.getElementById('home_slider_container'));
    root.render(
        <HomeSlider />
    )
}

// Promotions
if (document.getElementById('promotions_container')) {
    const root = ReactDOM.createRoot(document.getElementById('promotions_container'));
    root.render(
        <Promotions />
    )
}

// SEO Meta tags
if (document.getElementById('seo_meta_tags_container')) {
    const root = ReactDOM.createRoot(document.getElementById('seo_meta_tags_container'));
    root.render(
        <SeoMetaTags />
    )
}


// Purchase Links
if (document.getElementById('purchase_links_container')) {
    const root = ReactDOM.createRoot(document.getElementById('purchase_links_container'));
    root.render(
        <PurchaseLinks />
    )
}

//Uploaded Images Gallery
if (document.getElementById('uploaded_images_container')) {
    const root = ReactDOM.createRoot(document.getElementById('uploaded_images_container'));
    root.render(
        <UploadedImages multiple={false} selectable={false} />
    )
}
