import React, {useEffect, useState} from "react";
import {config} from "../../config";

export const PageSelector = ({page, model, onPageUpdate, onModelUpdate}) => {
    const [models, setModels] = useState([]);
    const [modelsVisible, setModelsVisible] = useState(false);

    const onPageEventUpdate = (e) => {
        onPageUpdate(e.target.value ?? page);
        getModels(e.target.value ?? page);
    }

    const onModelEventUpdate = (e) => {
        const value = e.target.value !== "" ? (e.target.value ?? null) : null;
        onModelUpdate(value);
    }

    const getModels = (type) => {
        const endpoint = config.promotions.pageSelector.models[type]?.endpoint ?? null;
        onModelUpdate(null);
        setModels([]);

        if (endpoint) {
            setModelsVisible(true);
            axios.get(endpoint).then(res => setModels(res.data ?? []));
        } else {
            setModelsVisible(false);
        }
    }

    useEffect(() => {
        if (page && config.promotions.pageSelector.pages.find(item => item.value === page)) {
            getModels(page);
        } else {
            onPageUpdate(config.promotions.pageSelector.pages[0].value);
            getModels(config.promotions.pageSelector.pages[0].value);
        }
    }, []);

    const getModelsOptions = () => {
        const type = page && config.promotions.pageSelector.pages.find(item => item.value === page) ? page : config.promotions.pageSelector.pages[0].value;

        const options = models.map((item, index) => {
            const labelKey = config.promotions.pageSelector.models[type]?.labelKey;
            const valueKey = config.promotions.pageSelector.models[type]?.valueKey;

            return <option key={index} value={item[valueKey] ?? ""}>{ item[labelKey]  ?? "" }</option>
        })

        return <>{options}</>;
    }

    return (
        <div className={"row"}>
            <div className={"col-md-6"}>
                <div className="form-group mg-b-0">
                    <label className="form-label">Page</label>
                    <select
                        className="form-control"
                        value={page ?? ""}
                        onChange={onPageEventUpdate}
                    >
                        {config.promotions.pageSelector.pages.map((item, index) => <option key={index}
                                                                                           value={item.value}> {item.label} </option>)}
                    </select>
                </div>
            </div>

            {modelsVisible ?
                <div className={"col-md-6"}>
                    <div className="form-group mg-b-0">
                        <label className="form-label">Model</label>
                        <select
                            className="form-control"
                            value={model ?? ""}
                            onChange={onModelEventUpdate}
                        >
                            <option value={""}>Select a specific model</option>
                            {getModelsOptions()}
                        </select>
                    </div>
                </div>
                :
                <></>
            }
        </div>
    )
}
