import {config} from "../config";
import {CustomForm} from "../Components/CustomForm/CustomForm";
import React from "react";

export const ProductsForm = ({editModel = null}) => {
    const endpoints = {
        layouts: config.products.endpoints.getLayouts,
        update: config.products.endpoints.update(editModel),
        edit: config.products.endpoints.find(editModel),
        store: config.products.endpoints.store,
        index: config.products.endpoints.index
    };

    return (
        <CustomForm
            endpoints={endpoints}
            layout={config.products.customFormInputs(config.products.endpoints.getLayouts, config.categories.endpoints.get)}
            textualIdGeneratorKey={"name"}
            editModel={editModel}
        />
    )
}
