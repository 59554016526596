import FeatherIcon from "feather-icons-react";
import React, {useEffect, useMemo, useRef, useState} from "react";
import ApiService from "../Services/ApiService";

export default function YakjaSelector({
                                          endpoint,
                                          title,
                                          onChange,
                                          selectedOptions,
                                          description = 'Select purchase link',
                                          ItemRender = null,
                                          multiple = false,
                                          labelKey = 'name',
                                          valueKey = 'id',
                                          keywordParamName = 'keyword',
                                          wrapResponseData = null,
                                          onBrowse = null,
                                          getLabel = null,
                                      }) {


    const [items, setItems] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const draggingItemRef = useRef();
    const placingDraggedItemRef = useRef();

    useEffect(() => getItems(null), []);

    const onDragStart = index => draggingItemRef.current = index;
    const onDragOver = index => placingDraggedItemRef.current = index;
    const onDragEnd = () => {
        if (multiple) {
            const result = Array.isArray(selectedOptions) ? [...selectedOptions] : [];
            let sortedItem = result.splice(draggingItemRef.current, 1)[0];
            result.splice(placingDraggedItemRef.current, 0, sortedItem);

            if (typeof onChange === 'function') {
                onChange(result);
            }
        }
    }

    const onItemsSuccessCallback = (data) => {
        if (typeof wrapResponseData === 'function') {
            setItems(() => wrapResponseData(data));
        } else {
            setItems(() => data);
        }
    }

    const api = useMemo(() => new ApiService({get: endpoint}), []);
    const getItems = (keyword) => api.get(
        onItemsSuccessCallback, null, null, {[keywordParamName]: keyword ?? ''}
    );

    const isSelected = (item) => {
        if (multiple && Array.isArray(selectedOptions)) {
            return !!selectedOptions.find((i) => item[valueKey] === i[valueKey]);
        } else if (!multiple && selectedOptions) {
            return selectedOptions[valueKey] === item[valueKey];
        }

        return false;
    }

    const onItemClicked = ((e, item) => {
        if (multiple) {
            e.stopPropagation();
        }

        let result;
        if (!multiple) {
            result = selectedOptions && selectedOptions[valueKey] === item[valueKey] ? null : item;
        } else {
            result = Array.isArray(selectedOptions) ? [...selectedOptions] : [];
            result = isSelected(item) ? result.filter(i => i[valueKey] !== item[valueKey]) : [...result, item];
        }

        if (typeof onChange === 'function') {
            onChange(result);
        }
    });


    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value ?? '');
        getItems(e.target.value ?? '');
    }

    getLabel = typeof getLabel === 'function' ? getLabel : (item) => typeof item === 'object' ? item[labelKey] : item;

    const DropDownItem = ({item}) => {
        return (
            <div className={`dropdown-item ${isSelected(item) ? 'active' : ''} `}
                 onClick={(e) => onItemClicked(e, item)}>
                {ItemRender !== null ?
                    <ItemRender item={item}/>
                    :
                    <span>{getLabel(item)}</span>
                }
            </div>
        );
    }

    const DropDownSelectedItems = () => {
        if (multiple && Array.isArray(selectedOptions) && selectedOptions.length) {
            const selectedOptionSpans = selectedOptions.map((item, index) => (
                <span
                    draggable={multiple}
                    className="badge badge-pill badge-primary badge-with-remove-btn ml-2"
                    key={index}
                    onDragStart={() => onDragStart(index)}
                    onDragOver={() => onDragOver(index)}
                    onDragEnd={onDragEnd}
                    onDragOverCapture={(e) => e.preventDefault()}
                >
                    {getLabel(item)}
                    <button onClick={(e) => onItemClicked(e, item)}>×</button>
                </span>
            ))

            return <p className={"m-0"}>{selectedOptionSpans}</p>
        } else if (!multiple && selectedOptions) {
            return <span className={"ml-2"}>{getLabel(selectedOptions)}</span>
        }

        return <span className={"ml-2"}>{description}</span>;
    }

    const options = items.map((item, index) => <DropDownItem item={item} key={index}/>)

    return (
        <div className="w-100 dropdown-select position-relative">
            <label className="form-label">{title}</label>
            <div
                className={"d-flex w-100 dropdown-select-btn-group position-relative justify-content-between align-items-center bg-white"}>
                <div className={"dropdown-select-label"}><DropDownSelectedItems/></div>
                <button className="btn dropdown-toggle border-0" data-toggle="dropdown" data-display="static">

                </button>

                <div className="dropdown-menu w-100 tx-13">
                    <div className={"dropdown-select-search w-100"}>
                        <FeatherIcon className={"search-icon"} style={{marginRight: "-2px"}} icon={"search"} size={18}/>
                        <input
                            type={"text"}
                            className={"form-control w-100 rounded-0"}
                            placeholder={"Type to search for purchase links"}
                            value={searchValue}
                            onChange={onSearchValueChange}
                        />
                        {(typeof onBrowse === 'function') ?
                            <FeatherIcon onClick={onBrowse} className={"browse-icon"} style={{marginRight: "-2px"}} icon={"external-link"}
                                         size={18}/>
                            :
                            <></>
                        }
                    </div>
                    {options.length === 0 ?
                        <div className={`dropdown-item disabled text-center`}>
                            <span>No results found</span>
                        </div>
                        :
                        <>{options}</>
                    }
                </div>
            </div>


        </div>
    )
}
