import React, {useEffect, useState} from "react";
import {getSelectedPromotionsForPage, storeSelectedPromotionsForPage} from "../../Services/PromotionService";
import {areSamArrayList} from "../../../helpers";
import {ContainerLoader} from "../../Components/ContainerLoader";

export const PromotionsSelector = ({page = 'home', model = null, promotions = []}) => {
    const [selectedPromotions, setSelectedPromotions] = useState([]);
    const [originalSelectedPromotions, setOriginalSelectedPromotions] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        onSelectedPromotionSuccess([]);
        getSelectedPromotionsForPage(onSelectedPromotionSuccess, setLoader, page, model);
    }, [page, model]);

    const onSelectedPromotionSuccess = (promotions) => {
        setSelectedPromotions(() => [...promotions]);
        setOriginalSelectedPromotions(() => [...promotions]);
    }

    const isSelected = (id) => {
        const found = selectedPromotions.find(e => e === id);

        return !!found;
    }

    const onChangeCheckbox = (id) => {
        setSelectedPromotions(current => {
            const index = current.indexOf(id);

            if(index >= 0) {
                current.splice(index, 1);
            } else {
                current.push(id);
            }

            return [...current];
        })
    }

    const onSave = () => {
        storeSelectedPromotionsForPage(onSelectedPromotionSuccess, setLoader, selectedPromotions, page, model);
    }

    return (
        <div className={"row mt-5"}>
            <div className={"col-12"}>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered mg-b-0 text-md-nowrap">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Promotion</th>
                            <th>Layout</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {promotions.map((item, index) => (
                            <tr key={index}>
                                <th className={"wd-100"} scope="row">
                                    <label className="ckbox">
                                        <input type="checkbox" checked={isSelected(item.id)} onChange={() => onChangeCheckbox(item.id)}/><span>{index + 1}</span>
                                    </label>
                                </th>
                                <td>{item.title}</td>
                                <td>{item.layout.title}</td>
                                <td><span
                                    className={`badge badge-pill badge-${item.status === 'Active' ? 'primary' : 'danger'}`}>{item.status}</span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {!areSamArrayList(originalSelectedPromotions, selectedPromotions) ?
                <div className={"col-12 mt-5 d-flex justify-content-end"}>
                    <button className={"btn btn-primary rounded-5"} onClick={onSave}>Save</button>
                </div>
                :
                <></>

            }

            <ContainerLoader visible={loader} />
        </div>
    )
}
