import {useState} from "react";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";
import {deleteCollection, restoreCollection} from "../../Services/CollectionService";

export const DeleteRestoreButton = ({collection, setCollection}) => {
    const [loader, setLoader] = useState(false);

    const onDelete = () => deleteCollection(collection, setCollection, setLoader);
    const onRestore = () => restoreCollection(collection, setCollection, setLoader);

    const title = collection.status.label === 'Removed' ? 'Permanently Delete' : 'Delete';
    const icon = collection.status.label === 'Removed' ? 'trash' : 'trash-2';

    return (
        <>
            <Tooltip title={title} arrow placement={"top"}>
                <button className={`btn btn-danger btn-icon p-0 rounded ml-3`} disabled={loader} onClick={onDelete}>
                    <FeatherIcon icon={loader ? 'loader' : icon}/>
                </button>
            </Tooltip>

            {(collection.status.label === 'Removed') &&
            <Tooltip title="Restore" arrow placement={"top"}>
                <button className={`btn btn-info btn-icon p-0 rounded ml-3`} disabled={loader} onClick={onRestore}>
                    <FeatherIcon icon={loader ? 'loader' : 'rotate-ccw'}/>
                </button>
            </Tooltip>
            }
        </>
    )
}
