import {CustomModal} from "../../Components/CustomModal";
import React, {useState} from "react";
import {PageSelector} from "./PageSelector";
import {PromotionsSelector} from "./PromotionsSelector";

export const PageSettingsModal = ({visible, promotions, hideModal}) => {
    const [page, setPage] = useState('home');
    const [model, setModel] = useState(null);

    return (
        <CustomModal
            visible={visible}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h6 className="modal-title">
                        Promotions configuration
                    </h6>
                    <button aria-label="Close" className="close" type="button" onClick={hideModal}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className={"card"} style={{backgroundColor: "#ffffff3d"}}>
                        <div className={"card-body"}>
                            <PageSelector
                                page={page}
                                model={model}
                                onPageUpdate={setPage}
                                onModelUpdate={setModel}
                            />

                            <PromotionsSelector
                                page={page}
                                model={model}
                                promotions={promotions}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}
