import React from 'react';
import {config} from "../config";
import {CustomForm} from "../Components/CustomForm/CustomForm";

export const CollectionForm = (props) => {
    const endpoints = {
        layouts: config.collections.endpoints.getLayouts,
        update: config.collections.endpoints.update(props.editModel ?? ""),
        edit: config.collections.endpoints.edit(props.editModel ?? ""),
        store: config.collections.endpoints.store,
        index: props.editModel ? config.collections.endpoints.show(props.editModel) : config.collections.endpoints.index
    };

    return (
        <CustomForm
            endpoints={endpoints}
            layout={config.collections.customFormInputs(config.categories.endpoints.get, config.collections.endpoints.getLayouts)}
            textualIdGeneratorKey={"name"}
            editModel={props.editModel ?? null}
        />
    )
}
