import {useCallback, useEffect, useMemo, useState} from "react"
import {config} from "../../config";
import Chart from "react-apexcharts";

export default function ResponsePieChart({filters}) {
    const [responseCodes, setResponseCodes] = useState([]),
        getResponseCode = useCallback((params) => {
            axios.get(config.dashboard.endpoints.stats + "/response_code", {params})
                .then(res => setResponseCodes(res.data))
        }, []),
        chartData = useMemo(() => {
            const labels = [];
            const series = [];

            responseCodes.forEach(item => {
                labels.push(item.response_code)
                series.push(item.total)
            })

            return {labels, series}
        }, [responseCodes])

    useEffect(() => {
        getResponseCode(filters)
    }, [filters]);

    return (
        <div className="col-md-6 mb-3">
            <div className="card custom-card h-100">
                <div className="card-header justify-content-between">
                    <div className="card-title mb-0">
                        Response Code
                    </div>
                </div>
                <div className="card-body p-0">
                    <Chart
                        type="pie"
                        height="400px"
                        options={{
                            chart: {
                                id: "basic-bar"
                            },
                            labels: chartData.labels
                        }}
                        series={chartData.series}
                    />
                </div>
            </div>
        </div>
    )
}
