import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

export const IconButton = (props) => {

    const {
        style = {},
        className = "btn btn-primary rounded-5",
        text = null,
        icon = "check-circle",
        tooltip = null
    } = props;

    const onClick = (event) => {
        if(typeof props.onClick === 'function') {
            props.onClick(event)
        }
    }

    const content = (
        <button disabled={props.loader} className={`icon-loading-button ${className}`} onClick={onClick} style={style}>
            {props.loader ?
                <div className="dimmer active">
                    <div className="lds-ring light">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                :
                <FeatherIcon size={18} icon={icon} style={{marginRight: text ? "10px": "0"}}/>
            }
            {text ?? ""}
        </button>
    )

    return tooltip ?
        <Tooltip title={tooltip} arrow placement={"top"}>
            {content}
        </Tooltip>
        :
        content;
}
