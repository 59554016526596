import {CustomFormRepeater} from "./CustomFormRepeater";
import React, {useEffect} from "react";
import CustomSimpleTextInputRepeater from "../CustomSimpleTextInputRepeater";

export const CustomProsAndCons = ({input, onChange, onReady, defaultValue}) => {
    const {divCustomClasses = 'col-md-12 mg-b-10 mg-t-10'} = input;
    useEffect(() => {
        if (typeof onReady === 'function') {
            onReady()
        }
    }, []);

    const onChangeInput = (id, value) => {
        if (typeof onChange === 'function') {
            const key = input.id + '.' + id;
            onChange(key, value);
        }
    }

    return (
        <div className={divCustomClasses}>
            <div className={"row gx-5"}>
                <div className={"col-md-6"}>
                    <CustomSimpleTextInputRepeater
                      title={"Advantages (Pros):"}
                      description={"Add advantages (pros)"}
                      label={"Pro:"}
                      placeholder={"Please write a pro"}
                      name={"pros"}
                      onChange={onChangeInput}
                      defaultValue={defaultValue && 'pros' in defaultValue && defaultValue.pros.length ? defaultValue.pros : null}
                    />
                </div>
                <div className={"col-md-6"}>
                    <CustomSimpleTextInputRepeater
                        title={"Disadvantages (Cons):"}
                        description={"Add disadvantages (cons)"}
                        label={"Con:"}
                        placeholder={"Please write a con"}
                        name={"cons"}
                        onChange={onChangeInput}
                        defaultValue={defaultValue && 'cons' in defaultValue && defaultValue.cons.length ? defaultValue.cons : null}
                    />
                </div>
            </div>
        </div>
    )
}
