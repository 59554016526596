import React, {useState} from "react";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";
import {deleteSlider, restoreSlider} from "../../Services/HomeSliderService";

export const ActionButtons = ({homeSlider, onEdit, setHomeSliders}) => {
    const [loader, setLoader] = useState(false);

    const onDelete = () => deleteSlider(homeSlider, setHomeSliders, setLoader);
    const onRestore = () => restoreSlider(homeSlider, setHomeSliders, setLoader);

    const title = homeSlider.status === 'Removed' ? 'Permanently Delete' : 'Delete';
    const icon = homeSlider.status === 'Removed' ? 'trash' : 'trash-2';

    return (
        <div className={"d-flex justify-content-start"}>
            <Tooltip title="Edit" arrow placement={"top"}>
                <button className="btn btn-indigo btn-icon p-0 rounded"
                        onClick={onEdit}>
                    <FeatherIcon icon={"edit"}/>
                </button>
            </Tooltip>

            <Tooltip title={title} arrow placement={"top"}>
                <button className={`btn btn-danger btn-icon p-0 rounded ml-3`} disabled={loader} onClick={onDelete}>
                    <FeatherIcon icon={loader ? 'loader' : icon}/>
                </button>
            </Tooltip>

            {(homeSlider.status === 'Removed') &&
            <Tooltip title="Restore" arrow placement={"top"}>
                <button className={`btn btn-info btn-icon p-0 rounded ml-3`} disabled={loader} onClick={onRestore}>
                    <FeatherIcon icon={loader ? 'loader' : 'rotate-ccw'}/>
                </button>
            </Tooltip>
            }
        </div>
    )
}
