import React, {useEffect, useState} from "react";
import {getStatus, updateStatus} from "../../Services/CollectionService";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";
import {Loader} from "../../Components/Loader";

export const StatusButtons = ({collection, setCollection}) => {
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [allowedStatus, setAllowedStatus] = useState([]);

    useEffect(() => getStatus(collection.id, setAllowedStatus, setError, setLoader), [collection.status]);

    const statuses = allowedStatus.map((item, index) => <StatusButton setCollection={setCollection} collection={collection} status={item} key={index}/>);

    return (
        <>
            {loader ?
                <Loader/>
                :
                <>
                    {statuses}
                </>
            }
        </>
    )
}

const StatusButton = ({collection, status, setCollection}) => {
    const [loader, setLoader] = useState(false);

    const onClick = () => updateStatus(collection.id, status.value, setCollection, setLoader);

    return (
        <Tooltip title={status.label} arrow placement={"top"}>
            <button className={`btn btn-${status.class} btn-icon p-0 rounded ml-3`} disabled={loader} onClick={onClick}>
                <FeatherIcon icon={loader ? 'loader' : status.icon}/>
            </button>
        </Tooltip>
    )
}
