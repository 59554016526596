import {useCallback, useEffect, useMemo, useState} from "react";

export default function useStats(filters, onFilterChange, endpoint, paginated = true) {
    const [isOpen, setIsOpen] = useState(false),
        [page, setPage] = useState(1),
        [isLoading, setIsLoading] = useState(false),
        [data, setData] = useState([]),
        [metaData, setMetaData] = useState({}),

        allFilters = useMemo(
            () => ({...(typeof filters === 'object' && filters ? filters : {}), ...(paginated ? {page} : {})}),
            [filters, page, paginated]
        ),

        handleOnPageChange = useCallback((p) => setPage(p), []),

        handleOnOpen = useCallback((e) => {
            e.preventDefault()
            setIsOpen(true)
        }, []),
        handleOnClose = useCallback(() => setIsOpen(false), []),

        getData = useCallback((params) => {
            setIsLoading(true)
            axios.get(endpoint, {params})
                .then((res) => {
                    if (paginated) {
                        setData(res.data.data)
                        setMetaData(res.data.metaData)
                    } else {
                        setData(res.data)
                    }
                    setIsLoading(false)
                })
        }, []),

        applyFilter = useCallback((key, value) => {
            return (e) => {
                e.preventDefault()
                if (typeof onFilterChange === 'function') {
                    onFilterChange(key, value)
                    handleOnClose()
                }
            }
        }, [onFilterChange])

    useEffect(() => {
        setPage(1)
    }, [filters])

    useEffect(() => {
        if (isOpen) {
            getData(allFilters)
        }
    }, [isOpen, allFilters])

    return {
        isOpen,
        handleOnOpen,
        handleOnClose,
        handleOnPageChange,
        page,
        isLoading,
        data,
        metaData,
        applyFilter
    }
}
