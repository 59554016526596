import {useCallback, useEffect, useMemo, useState} from "react"
import {config} from "../../config"

const formFilterKeys = ['from', 'to', 'country_code', 'response_code']

export default function useFilters({filters, onFilterChange, onClearCallback}) {
    const [visible, setVisible] = useState(false),
        [canLookup, setCanLookup] = useState(false),
        doesHaveFilters = useMemo(() => Object.keys(filters).length, [filters]),
        areFiltersVisible = useMemo(() => doesHaveFilters || visible, [doesHaveFilters, visible]),
        [countries, setCountries] = useState([]),
        [responseCodes, setResponseCodes] = useState([]),

        tagFilters = useMemo(() => {
            const obj = {}
            Object.keys(filters ?? {}).forEach(key => {
                if (formFilterKeys.indexOf(key) < 0) {
                    obj[key] = filters[key]
                }
            })
            return obj
        }, [filters]),

        onShow = () => setVisible(true),
        onHide = () => {
            setVisible(false)
            onClearCallback()
        },

        getCountries = useCallback(() => {
            axios.get(config.dashboard.endpoints.stats + "/country_code", {params: {orderBy: 'country_code'}})
                .then(res => setCountries(res.data))
        }, []),

        getResponseCodes = useCallback(() => {
            axios.get(config.dashboard.endpoints.stats + "/response_code", {params: {orderBy: 'response_code'}})
                .then(res => setResponseCodes(res.data))
        }, []),

        getLookupStatus = useCallback(() => {
            axios.get(config.dashboard.endpoints.lookup)
                .then(res => setCanLookup(!!res.data?.result))
        }, []),

        onLookUp = useCallback(() => {
            setCanLookup(false)
            axios.patch(config.dashboard.endpoints.lookup)
                .then(() => window.location.reload())
        }, []),

        onChange = useCallback((e) => {
            if (typeof onFilterChange === 'function') {
                onFilterChange(e.target.name, e.target.value)
            }
        }, [onFilterChange])


    useEffect(() => {
        getCountries()
        getResponseCodes()
        getLookupStatus()
    }, [])

    return {
        visible: areFiltersVisible,
        doesHaveFilters,
        countries,
        responseCodes,
        tagFilters,
        onChange,
        canLookup,
        onShow,
        onHide,
        onLookUp
    }
}
