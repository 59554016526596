import React, {useState} from "react";

export const CustomImage = (props) => {
    const [loading, setLoading] = useState(true)

    return (
        <React.Fragment>
            <div className={"image-placeholder"} style={{display: loading ? "flex" : "none"}}>
                <div className="dimmer active">
                    <div className="spinner1-lg">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                </div>
            </div>
            <div style={{display: loading ? "none" : "block"}}>
                <img
                    key={props.src}
                    onLoad={() => setLoading(false)}
                    {...props}
                />
            </div>
        </React.Fragment>

    )
}
