import React from "react";

export function ComponentLoader({visible = false}) {
    if (!visible) {
        return <></>
    }

    return (
        <div className={"position-absolute w-100 h-100"} style={{top: 0, left: 0}}>
            <div className={"w-100 h-100 bg-dark-transparent d-flex justify-content-center align-items-center"}>
                <div className="dimmer active">
                    <div className="lds-ring">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            </div>
        </div>
    )
}
