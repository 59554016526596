import React from "react";
import {config} from "../../config";
import {CustomSelect} from "../../Components/CustomForm/Inputs/CustomSelect";
import {CustomTextInput} from "../../Components/CustomForm/Inputs/CustomTextInput";

export default function PurchaseLinksFilters({onChange, filters}) {

    const onChangeFilter = (key, value) => {
        if (typeof onChange === 'function') {
            onChange(key, value.length > 0 ? value : null);
        }
    }

    return (
        <div className={"mt-3 p-4 bg-white rounded-5"}>
            <div className={"row"}>
                <div className={"col-12"}><h4>Filter by:</h4></div>

                <CustomTextInput
                    onChange={onChangeFilter}
                    defaultValue={filters.name ?? ''}
                    input={{
                        id: 'name',
                        title: 'Name:',
                        description: 'Search by name',
                        divCustomClasses: 'col-sm-3',
                    }}
                />

                <CustomSelect
                    onChange={onChangeFilter}
                    input={{
                        id: 'seller',
                        title: 'Seller:',
                        description: 'Filter by seller',
                        divCustomClasses: 'col-sm-3',
                        dataSource: {
                            endpoint: config.sellers.endpoints.get
                        }
                    }}
                />

                <CustomTextInput
                    onChange={onChangeFilter}
                    defaultValue={filters.url ?? ''}
                    input={{
                        id: 'url',
                        title: 'URL:',
                        description: 'Search by URL',
                        divCustomClasses: 'col-sm-3',
                    }}
                />

                <CustomSelect
                    onChange={onChangeFilter}
                    input={{
                        id: 'status',
                        title: 'Status:',
                        description: 'Filter by status',
                        divCustomClasses: 'col-sm-3',
                        options: [
                            {label: 'Active', value: 'active'},
                            {label: 'Disabled', value: 'disabled'}
                        ]
                    }}
                />
            </div>
        </div>
    )
}
