import React, {useEffect, useState} from "react";
import {CustomInput} from "./CustomInput";

export const LayoutInputs = (props) => {
    const [inputs, setInputs] = useState([]);
    const isReadyList = [];

    useEffect(() => {
        if (props.layout !== null && Array.isArray(props.layout.inputs)) {
            let inputs = props.layout.inputs.map((item, index) => {
                isReadyList.push({id: item.id, status: false});
                return (
                    <React.Fragment key={index}>
                        <CustomInput
                            input={item}
                            onChange={onChange}
                            defaultValue={props.defaultValue ?? null}
                            onReady={() => onReady(item.id)}
                            textualIdRef={props.textualIdRef ?? null}
                        />
                    </React.Fragment>
                )
            });

            setInputs(inputs);
        }
    }, [props.layout, props.defaultValue])

    const onChange = (id, value) => {
        if (typeof props.onChange === 'function') {
            props.onChange(id, value);
        }
    }

    const onReady = (id) => {
        for(let i in isReadyList) {
            if(isReadyList[i].id === id) {
                isReadyList[i].status = true;
            }
        }

        let result = true;

        for(let i in isReadyList) {
            if(isReadyList[i].status === false) {
                result = false;
            }
        }

        if(result === true && typeof props.onReady === 'function') {
            props.onReady(true);
        }
    }

    if (props.layout !== null) {
        return (
            <React.Fragment>
                {inputs}
            </React.Fragment>
        )
    }

    return (
        <div className={"col-12"}>
            <p className={"text-center text-warning tx-18"}>Please select a layout</p>
        </div>
    )
}
