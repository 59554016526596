import React, {useMemo, useRef} from "react";
import {config} from "../../config";
import {CustomForm} from "../../Components/CustomForm/CustomForm";
import {CSSTransition} from "react-transition-group";

export const SellersForm = ({visible, onSuccess}) => {
    const endpoints = useMemo(() => config.sellers.endpoints, []);
    const newSellerForm = useRef();

    return (
        <CSSTransition
            in={visible}
            nodeRef={newSellerForm}
            timeout={300}
            classNames="alert"
            unmountOnExit
        >
            <div ref={newSellerForm} className={"row justify-content-center mt-2"}>
                <div className={"col-12"}>
                    <div className="card mg-b-20">
                        <div className="card-body">
                            <CustomForm
                                endpoints={endpoints}
                                layout={config.sellers.customFormInputs}
                                onSuccess={onSuccess}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}
