import React, {useCallback, useEffect, useMemo, useState} from "react"
import {config} from "../../config"
import FeatherIcon from "feather-icons-react"
import {CustomModal} from "../../Components/CustomModal"
import {CustomPagination} from "../../Components/CustomPagination"
import {truncateString} from "../../../helpers"
import useStats from "./useStats";

export default function RouteStats({filters = {}, count, onFilterChange}) {
    const {
        isOpen,
        handleOnOpen,
        handleOnClose,
        handleOnPageChange,
        page,
        isLoading,
        data,
        metaData,
        applyFilter
    } = useStats(filters, onFilterChange, config.dashboard.endpoints.stats + "/route")

    return (
        <div className="card">
            <div className="card-body">
                <a href="#" className="d-flex justify-content-between align-items-center" onClick={handleOnOpen}>
                    <div>
                        <p className=" mb-0">Routes</p>
                        <h3 className="mb-0">{count ?? 0}</h3>
                    </div>
                    <div className="ms-auto text-end">
                        <div className="bg-primary-transparent text-primary rounded-circle p-2">
                            <FeatherIcon icon={"link"}/>
                        </div>
                    </div>
                </a>

                <CustomModal visible={isOpen}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title">
                                Routes
                            </h6>
                            <button aria-label="Close" className="close" type="button" onClick={handleOnClose}>
                                <span>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {isLoading ?
                                <div className="dimmer active">
                                    <div className="spinner1-lg">
                                        <div className="double-bounce1"></div>
                                        <div className="double-bounce2"></div>
                                    </div>
                                </div>
                                :
                                <div className="table-responsive text-nowrap w-100">
                                    <table className="table">
                                        <thead>
                                        <tr className="bg-white">
                                            <th>#</th>
                                            <th className="wd-150">Route</th>
                                            <th className="text-center">Visits</th>
                                            <th className="text-center">Users</th>
                                            <th className="text-center">Countries</th>
                                            <th className="text-center">Cities</th>
                                            <th className="text-center">ISPs</th>
                                            <th>First Access</th>
                                            <th>Last Access</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(Array.isArray(data) ? data : []).map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + parseInt(metaData?.from ?? 1)}</td>
                                                <td className="text-nowrap wd-150">
                                                    <a href={`https://yakja.co/${item.route}`}
                                                       onClick={applyFilter('route', item.route)}>
                                                        {truncateString(item.route)}
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    {item.total}
                                                </td>
                                                <td className="text-center">
                                                    {item.users}
                                                </td>
                                                <td className="text-center">
                                                    {item.countries}
                                                </td>
                                                <td className="text-center">
                                                    {item.cities}
                                                </td>
                                                <td className="text-center">
                                                    {item.isps}
                                                </td>
                                                <td>
                                                    {item.first_access}
                                                </td>
                                                <td>
                                                    {item.last_access}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    <CustomPagination
                                        current={page}
                                        onPageChange={handleOnPageChange}
                                        perPage={metaData.perPage ?? 15}
                                        total={metaData.total ?? 0}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </CustomModal>
            </div>
        </div>
    )
}
