import FeatherIcon from "feather-icons-react";
import React from "react";
import {ActionButtons} from "./ActionButtons";

export const CollectionHeader = ({collection, setCollection}) => {
    return (
        <div className="main-content-header p-4 bg-white mt-2 rounded-5 d-block">
            <div className="row justify-content-center align-items-center justify-content-md-between">
                <div className="col-12 col-md-8">
                    <CollectionTitle title={collection.title} status={collection.status}/>

                    <a href={collection.url} className="main-content-text tx-13 mg-b-0" target="_blank">
                        {collection.url}
                    </a>
                </div>
                <div className="col-12 col-md-4 mt-3 mt-md-0">
                    <div className="btn-icon-list justify-content-center justify-content-md-end">
                        <ActionButtons collection={collection} setCollection={setCollection}/>
                    </div>
                </div>
            </div>

        </div>
    )
}

function CollectionTitle({title, status}) {
    const titleElements = title.map((item, index) => {
        return (
            <React.Fragment key={index}>
                {item}
                {index !== (title.length - 1) &&
                    <FeatherIcon
                        icon={"chevron-right"}
                        className={"feather tx-gray-600 mg-l-10 mg-r-10"}
                        width={15}
                        height={15}
                        strokeWidth={4}
                    />
                }
            </React.Fragment>
        )
    })

    return (
        <div className="d-flex">
            <h6 className="main-content-title tx-14 mg-b-5 mg-t-3 d-flex align-items-center">{titleElements}</h6>
            <span
                className={`badge badge-pill badge-${status.class}`}>
                {status.label}
            </span>
        </div>
    )
}
