import React, {Fragment} from "react";
import FeatherIcon from "feather-icons-react";
import {truncateString} from "../../../helpers";
import useFilters from "./useFilters";

export default function Filters({filters, onFilterChange, onClear}) {
    const {
        visible,
        doesHaveFilters,
        countries,
        responseCodes,
        tagFilters,
        onChange,
        canLookup,
        onShow,
        onHide,
        onLookUp
    } = useFilters({filters, onFilterChange, onClearCallback: onClear})

    return (
        <div className="row justify-content-end align-items-end mb-3">
            {visible &&
                <>
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group m-0">
                            <label className="form-label">Date From</label>
                            <input type="date" className="form-control" name="from"
                                   onChange={onChange} value={filters?.from ?? ''}/>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3">
                        <div className="form-group m-0">
                            <label className="form-label">Date To</label>
                            <input type="date" className="form-control" name="to"
                                   onChange={onChange} value={filters?.to ?? ''}/>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3">
                        <div className="form-group m-0">
                            <label className="form-label">Country</label>
                            <select className="form-control" name="country_code" onChange={onChange}
                                    value={filters?.country_code ?? ''}>
                                <option value="">Select</option>
                                {(Array.isArray(countries) ? countries : []).map((item, index) => (
                                    <option value={item.country_code} key={index}>{item.country.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-2">
                        <div className="form-group m-0">
                            <label className="form-label">Response</label>
                            <select className="form-control" name="response_code" onChange={onChange}
                                    value={filters?.response_code ?? ''}>
                                <option value="">Select</option>
                                {(Array.isArray(responseCodes) ? responseCodes : []).map((item, index) => (
                                    <option value={item.response_code} key={index}>{item.response_code}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </>
            }


            <div className={"col-1 d-flex justify-content-end align-items-end"}>
                {visible &&
                    <button
                        className={`btn ${doesHaveFilters ? 'btn-secondary' : 'btn-outline-light'} py-2`}
                        onClick={onHide}>
                        {doesHaveFilters ? "Clear" : "Cancel"}
                    </button>
                }

                {!visible &&
                    <>
                        <button
                            className="btn btn-sm btn-secondary btn-icon rounded-5 p-0 d-flex justify-content-center align-items-center mr-3"
                            onClick={onLookUp} disabled={!canLookup}>
                            <FeatherIcon icon={"refresh-ccw"} className="icon m-0" style={{width: "16px", height: "16px"}}/>
                        </button>

                        <button
                            className="btn btn-sm btn-primary btn-icon rounded-5 p-0 d-flex justify-content-center align-items-center"
                            onClick={onShow}>
                            <FeatherIcon icon={"filter"} className="icon m-0" style={{width: "16px", height: "16px"}}/>
                        </button>
                    </>
                }
            </div>

            {tagFilters && Object.keys(tagFilters).length ?
                <div className="col-12 mt-3">
                    {Object.keys(tagFilters).map((key, index) => (
                        <Fragment key={index}>
                            {tagFilters[key] &&
                                <span className="badge badge-warning mr-3 rounded-pill">
                                    {truncateString(tagFilters[key])} - {key}

                                    <button className="close" onClick={() => onFilterChange(key, null)}>x</button>
                                </span>
                            }
                        </Fragment>
                    ))}
                </div>
                :
                <></>
            }
        </div>
    )
}
