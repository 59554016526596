import React, {useEffect, useMemo, useState} from "react";
import YakjaSelector from "../../YakjaSelector";
import {config} from "../../../config";
import {PurchaseLinkPicker} from "../../../PurchaseLinks/PurchaseLinkPicker";
import {getSelectedIds} from "../../../../helpers";
import ApiService from "../../../Services/ApiService";

export const CustomPurchaseLinkInput = ({defaultValue, input, onReady, onChange, multiple = false}) => {
    const [selected, setSelected] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const {divCustomClasses = 'col-md-6 mg-b-10'} = input;

    useEffect(() => {
        if(typeof onReady === 'function') {
            onReady()
        }
    }, []);

    useEffect(() => {
        if (selected === null) {
            getDefaultSelectedOptions(defaultValue);
        }
    }, [defaultValue]);

    const onUpdated = (data) => {
        setSelected(data);

        if (typeof onChange === 'function') {
            onChange(input.id, getSelectedIds(data, multiple))
        }
    }

    const api = useMemo(() => new ApiService({}), []);

    const getDefaultSelectedOptions = (defaultValue) => {
           if (!multiple && defaultValue) {
               api.setEndpoints({get: config.purchaseLinks.endpoints.get + `/${defaultValue}`});
               api.get(setSelected);
           } else if (multiple && Array.isArray(defaultValue)) {
               for (let i in defaultValue) {
                   api.setEndpoints({get: config.purchaseLinks.endpoints.get + `/${defaultValue[i]}`});
                   api.get((pl) => {
                       setSelected((current) => {
                          current = Array.isArray(current)  ? current : [];
                          return [...current, pl];
                       });
                   });
               }
           }
    }

    return (
        <div className={divCustomClasses}>
            <YakjaSelector
                endpoint={config.purchaseLinks.endpoints.get}
                selectedOptions={selected}
                onChange={onUpdated}
                title={input.title}
                description={input.description}
                multiple={multiple}
                getLabel={(item) => `${item.name} - ${item.currency}${item.price} at ${item.seller}`}
                wrapResponseData={(data) => data.data}
                onBrowse={() => setShowModal(true)}
            />

            <PurchaseLinkPicker
                selectedOptions={selected}
                onChange={onUpdated}
                onHide={() => setShowModal(false)}
                multiple={multiple}
                visible={showModal}
            />
        </div>
    )
}
