import React, {useRef, useState} from "react";
import {PurchaseLinksHeader} from "./Partials/PurchaseLinksHeader";
import {PurchaseLinksForm} from "./Partials/PurchaseLinksForm";
import {PurchaseLinkTable} from "./Partials/PurchaseLinkTable";
import PurchaseLinksFilters from "./Partials/PurchaseLinksFilters";
import {SellersForm} from "./Partials/SellersForm";
import {showToast} from "../Components/CustomAlerts";

export const PurchaseLinks = ({selectable = false, multiple = false, selectedOptions, onItemSelected}) => {
    const [showFormModal, setShowFormModal] = useState(false);
    const [showNewSellerModal, setShowNewSellerModal] = useState(false);
    const [editablePurchaseLink, setEditablePurchaseLink] = useState(null);
    const [filters, setFilters] = useState({});

    const tableRef = useRef();

    const onEdit = (item) => {
        setEditablePurchaseLink(item);
        setShowFormModal(true);
    }

    const onHideForm = () => {
        setEditablePurchaseLink(null);
        setShowFormModal(false);
        setShowNewSellerModal(false);
    }

    const onHeaderStateUpdate = (state) => {
        setShowFormModal(state.purchaseLinkForm);
        setShowNewSellerModal(state.sellerForm);
    }

    const onChangeFilters = (key, value) => {
        setFilters((current) => {
            current[key] = value;

            if (key === 'page') {
                current['model'] = null;
            }

            return {...current};
        })
    }

    const onPurchaseLinkFormSuccess = () => {
        const msg = editablePurchaseLink ? editablePurchaseLink.name + ' updated successfully' : 'New purchase link added successfully';
        showToast(msg);
        onHideForm();
        if (typeof tableRef.current?.refreshTable === 'function') {
            console.log("Yes");
            tableRef.current.refreshTable()
        }
    }

    const onSellerFormSuccess = () => {
        const msg = 'New seller added successfully';
        showToast(msg);
        setShowNewSellerModal(false);
    }

    return (
        <div>
            <PurchaseLinksHeader
                onStateUpdate={onHeaderStateUpdate}
                formVisible={(showFormModal && !editablePurchaseLink) || showNewSellerModal}
            />

            <PurchaseLinksForm
                visible={showFormModal}
                onHide={onHideForm}
                purchaseLink={editablePurchaseLink}
                onSuccess={onPurchaseLinkFormSuccess}
            />

            <SellersForm
                visible={showNewSellerModal}
                onSuccess={onSellerFormSuccess}
            />

            <PurchaseLinksFilters
                filters={filters}
                onChange={onChangeFilters}
            />

            <PurchaseLinkTable
                ref={tableRef}
                onEdit={onEdit}
                filters={filters}
                onPageChange={(page) => onChangeFilters('page', page)}
                selectable={selectable}
                multiple={multiple}
                selectedOptions={selectedOptions}
                onItemSelected={onItemSelected}
            />
        </div>
    );
}
