import React from 'react';
import {config} from "../config";
import {CustomForm} from "../Components/CustomForm/CustomForm";

export const CategoriesForm = (props) => {
    const endpoints = {
        layouts: config.categories.endpoints.getLayouts,
        update: config.categories.endpoints.update(props.editModel ?? ""),
        edit: config.categories.endpoints.find(props.editModel ?? ""),
        store: config.categories.endpoints.store,
        index: config.categories.endpoints.index
    };


    return (
        <CustomForm
            endpoints={endpoints}
            layout={config.categories.customFormInputs(config.categories.endpoints.getLayouts, config.categories.endpoints.get, props.editModel ?? null)}
            textualIdGeneratorKey={"name"}
            editModel={props.editModel ?? null}
        />
    )
}


