import React from 'react';
import axios from 'axios';
import {UploadImageHeader} from "./partials/UploadImageHeader";
import {config} from "../config";
import {UploadedImageCard} from "./partials/UploadedImageCard";
import {CustomPagination} from "../Components/CustomPagination";
import {ContainerLoader} from "../Components/ContainerLoader";

export default class UploadedImages extends React.Component {
    constructor(props) {
        super(props);
        this.preSelected = props.selected;

        this.state = {
            data: [],
            metadata: {},
            page: 1,
            error: null,
            loader: true,
            params: {},
            selectedImages: [],
        }
    }

    componentDidMount() {
        this.getImages();
    }

    getImages = () => {
        const params = {
            page: this.state.page,
            selected: this.preSelected ?? [],
            ...this.state.params
        }

        axios.get(config.uploadedImages.endpoints.get, {params: params})
            .then((res) => {
                if (res.data.metadata && res.data.data) {
                    this.setState({metadata: res.data.metadata, data: res.data.data});
                } else {
                    this.setState({error: "Error occurred"});
                }
            })
            .catch((err) => {
                this.setState({error: err.response.data.message ?? err.toJSON()});
            })
            .finally(() => {
                this.setState({loader: false});
            })
    }

    onUploadComplete = (image) => {
        this.setState({page: 1, loader: true}, () => {
            this.getImages();
        })
    }

    onPageChange = (page) => {
        this.setState({
            page: page,
            loader: true
        }, () => {
            this.getImages()
        })
    }

    onSearchSubmitted = (params) => {
        this.setState({params: params}, () => this.onPageChange(1));
    }

    renderImages = () => {
        const images = this.state.data.map((item, index) => {
            return (
                <UploadedImageCard
                    image={item}
                    key={index}
                    selectable={this.props.selectable}
                    multiple={this.props.multiple}
                    selected={!!this.props?.selected?.find(i => i === item.id)}
                    onRefresh={() => this.onPageChange(this.state.page)}
                    onImageSelected={this.props.onImageSelected ?? null}
                    onImageRemoved={this.props.onImageRemoved ?? null}
                />
            )
        });

        return images.length ? (
            <div className="row row-sm row-cards row-deck">
                {images}
            </div>
        ) : (
            <div className="row row-sm row-cards row-deck">
                <div className={"col-12 text-center"}>
                    <p className={"text-center text-danger"}>
                        There are no images
                    </p>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="container">

                <UploadImageHeader
                    onUploadComplete={this.onUploadComplete}
                    onSearchSubmitted={this.onSearchSubmitted}
                />

                <div className="row justify-content-center mt-2">

                    <div className="col-12">
                        <div className="card mg-b-20">
                            <div className="card-body">
                                {this.renderImages()}
                            </div>
                        </div>
                    </div>

                    <CustomPagination
                        current={this.state.page}
                        onPageChange={this.onPageChange}
                        perPage={this.state.metadata.perPage ?? 10}
                        total={this.state.metadata.total ?? 0}
                    />
                </div>

                <ContainerLoader visible={this.state.loader} />
            </div>
        );
    }
}
