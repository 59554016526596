import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {CustomModal} from "../Components/CustomModal";
import UploadedImages from "./UploadedImages";
import {config} from "../config";

export const CustomImageInput = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [selectedImages, setSelectedImages] = useState([]);

    const onImageSelected = (image) => {
        if(!props.multiple) {
            setSelectedImages([image]);
            onDoneSingle(image);
        } else {
            setSelectedImages((currentImages) => {
                let newImages = [...currentImages];
                if(!newImages.find(item => item.id === image.id)) {
                    newImages = [...newImages, image];
                }

                return newImages;
            })
        }
    }

    const onImageRemoved = (image) => {
        if(!props.multiple && selectedImages.length && selectedImages[0].id === image.id) {
            setSelectedImages([]);
            onDoneSingle(null);
        } else if (props.multiple && selectedImages.length) {
            setSelectedImages((currentImages) => {
                currentImages = currentImages.filter(item => item.id !== image.id);
                return currentImages;
            })
        }
    }

    const onDoneSingle = (image) => {
        setImageUrl(image?.url ?? "");
        setShowModal(false);
        if(typeof props.onChange === 'function') {
            props.onChange(image?.id ?? null);
        }
    }

    const onDoneMultiple = () => {
        if(selectedImages.length) {
            setImageUrl(
                selectedImages.length === 1 ?
                    selectedImages[0].url :
                    `${selectedImages.length} images selected`
            );
        } else {
            setImageUrl("");
        }

        setShowModal(false);

        if(typeof props.onChange === 'function') {
            props.onChange(getSelectedIds());
        }
    }

    const getSelectedIds = () => {
        return Array.isArray(selectedImages) ? selectedImages.map(item => item.id) : [];
    }

    useEffect(() => {
        if(props.defaultValue) {
            axios.get(config.uploadedImages.endpoints.getSelected , {params: {ids: props.defaultValue}})
                .then((res) => {
                    if(res.data && res.data.length > 0) {
                        setSelectedImages(res.data);
                        setImageUrl(
                            (!props.multiple || res.data.length === 1) ?
                                res.data[0].url
                                :
                                `${res.data.length} images selected`
                        );
                    }
                })
        }
    }, [props.defaultValue])

    return (
        <div className={"uploaded-images-modal"}>
            <div className="input-group">
                <input
                    className="form-control"
                    placeholder="Click on browse to select/upload image"
                    type="text"
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                    readOnly={true}
                />
                <span className="input-group-btn">
                    <button className="btn btn-primary browse_image_label" type="button" onClick={() => setShowModal(true)}>
                        Browse
                    </button>
                </span>
            </div>

            <CustomModal
                visible={showModal}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title">Select / Upload Image</h6>
                        <button aria-label="Close" className="close" type="button" onClick={() => setShowModal(false)}>
                            <span>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <UploadedImages
                            selectable={true}
                            multiple={props.multiple ?? false}
                            selected={getSelectedIds()}
                            onImageSelected={onImageSelected}
                            onImageRemoved={onImageRemoved}
                        />
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                        <button className="btn btn-outline-light" type="button" onClick={() => setShowModal(false)}>Close</button>
                        {props.multiple &&
                            <button className="btn btn-primary" type="button" onClick={onDoneMultiple}>Done</button>
                        }

                    </div>
                </div>
            </CustomModal>
        </div>
    )
}
