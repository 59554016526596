import Swal from 'sweetalert2'

export const CustomAlert = (title, text, resolve, reject, params) => {
    Swal.fire({
        title: title,
        html: text,
        icon: params?.icon ?? undefined,
        imageUrl: params?.imageUrl ?? undefined,
        imageWidth: params?.imageUrl ? 400 : undefined,
        imageHeight: params?.imageUrl ? 200 : undefined,
        showDenyButton: params?.denyButton ?? true,
        confirmButtonText: params?.confirmButtonText ?? "Yes",
        denyButtonText: params?.denyButtonText ?? "No",
    }).then((result) => {
        if(result.isConfirmed && typeof resolve === 'function') {
            resolve();
        } else if (result.isDenied && typeof reject === 'function') {
            reject();
        }
    })
}


export const CustomToast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const showToast = (message, icon) => {
    const toastIcon = icon ?? 'success';
    CustomToast.fire({
        icon: toastIcon,
        title: message
    })
}

