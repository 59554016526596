import {useState, useRef} from "react";
import {CSSTransition} from 'react-transition-group';
import {CustomImage} from "../../Components/CustomImage";
import {config} from "../../config";
import {CustomAlert, showToast} from "../../Components/CustomAlerts";
import {IconButton} from "../../Components/IconButton";
import FeatherIcon from "feather-icons-react";

export const UploadedImageCard = (props) => {
    const [overlay, setOverlay] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const nodeRef = useRef(null);

    const onDelete = () => {
        const endpoint = config.uploadedImages.endpoints.delete(props.image.id);
        CustomAlert(
            "Delete Image",
            "Are you sure you want to delete this image?",
            () => {
                setDeleteLoader(true);
                axios.delete(endpoint)
                    .then((res) => {
                        showToast("Image successfully deleted");
                        if (typeof props.onRefresh === 'function') {
                            props.onRefresh();
                        }
                    })
                    .catch((err) => {
                        showToast("Error Occurred, try again", "error");
                    })
                    .finally(() => {
                        setDeleteLoader(false);
                    })
            },
            null,
            {
                imageUrl: props.image.url
            }
        );
    }

    const onCopy = (embed) => {
        let text = props.image.url;
        let toast = "Image URL added to clipboard";

        if (embed) {
            text = `<img src="${props.image.url}" alt="${props.image.title}" />`;
            toast = "Image embed code added to clipboard"
        }

        navigator.clipboard.writeText(text)
            .then(() => {
                showToast(toast);
            })

    }

    return (
        <div className="col-md-4">
            <div className="card mg-b-20 image-with-overlay" onMouseEnter={() => setOverlay(true)}
                 onMouseLeave={() => setOverlay(false)}>
                <CustomImage
                    className="card-img-top br-tr-0 br-tl-0"
                    src={props.image.thumbnails?.medium ?? props.image.url}
                    alt={props.image.title}
                    key={props.image.url}
                />
                <CSSTransition
                    in={overlay || props.selected}
                    nodeRef={nodeRef}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                >
                    <div ref={nodeRef} className="image-overlay">
                        <div className={"image-overlay-content flex-column"}>
                            {(props.selectable && props.multiple) &&
                            <div className={"pos-absolute t-5 r-5"}>
                                <IconButton
                                    className={"btn btn-light rounded-30 p-0 justify-content-center wd-25-f ht-25-f"}
                                    onClick={() => props.selected ? props.onImageRemoved(props.image) : props.onImageSelected(props.image)}
                                    icon={props.selected ? 'check-circle' : 'circle'}
                                    loader={false}
                                />
                            </div>
                            }

                            <div>
                                <p className={"text-center tx-18"}>{props.image.title}</p>
                                <p className={"text-center tx-18"}>
                                    {props.image.width} X {props.image.height} ({getSize(props.image.size)})
                                </p>
                            </div>
                            <div className={"d-flex"}>
                                {(props.selectable && !props.multiple) &&
                                <IconButton
                                    className={"btn btn-success rounded-5 mg-r-10 mg-l-10"}
                                    onClick={() => props.selected ? props.onImageRemoved(props.image) : props.onImageSelected(props.image)}
                                    icon={props.selected ? 'x-circle' : 'check-circle'}
                                    loader={false}
                                />
                                }
                                <IconButton
                                    className={"btn btn-indigo rounded-5 mg-r-10 mg-l-10"}
                                    onClick={() => onCopy(true)}
                                    icon={"code"}
                                    loader={false}
                                />
                                <IconButton
                                    className={"btn btn-info rounded-5 mg-r-10 mg-l-10"}
                                    onClick={() => onCopy(false)}
                                    icon={"link"}
                                    loader={false}
                                />
                                <IconButton
                                    className={"btn btn-primary rounded-5 mg-r-10 mg-l-10"}
                                    onClick={() => window.open(props.image.url)}
                                    icon={"zoom-in"}
                                    loader={false}
                                />
                                <IconButton
                                    className={"btn btn-danger rounded-5  mg-r-10 mg-l-10"}
                                    onClick={onDelete}
                                    icon={"trash"}
                                    loader={deleteLoader}
                                />
                            </div>

                        </div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    )
}

export const getSize = (bytes) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`
}
