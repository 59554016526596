import {CustomModal} from "../../Components/CustomModal";
import React, {useEffect, useMemo, useState} from "react";
import {config} from "../../config";
import {CustomForm} from "../../Components/CustomForm/CustomForm";
import {ContainerLoader} from "../../Components/ContainerLoader";

export const SeoMetaTagForm =  ({seoMetaTag = null, visible, onHide}) => {
    const [selectedPage, setSelectedPage] = useState(seoMetaTag?.page ?? null);
    const [loader, setLoader] = useState(false);
    const [key, setKey] = useState(0);

    useEffect(() => {
        if (seoMetaTag && 'page' in seoMetaTag && seoMetaTag.page !== selectedPage) {
            setSelectedPage(seoMetaTag.page);
            setKey(current => current + 1);
        }
    }, [seoMetaTag]);

    const formLayoutData = useMemo(() => {
        const dataSource = selectedPage in config.seoMetaTags.pageSelector.models ? config.seoMetaTags.pageSelector.models[selectedPage] : null;
        return config.seoMetaTags.customFormInputs(config.seoMetaTags.pageSelector.pages, dataSource);
    }, [selectedPage]);

    const onChange = (key, value) => {
        if (key === 'page') {
            setSelectedPage(value);
        }
    }

    const endpoints = {
        update: config.seoMetaTags.endpoints.update(seoMetaTag?.id ?? null),
        edit: config.seoMetaTags.endpoints.find(seoMetaTag?.id ?? null),
        store: config.seoMetaTags.endpoints.store,
        index: config.seoMetaTags.endpoints.index
    };

    return (
        <SeoMetaTagFormModal
            visible={visible}
            onHide={onHide}
            seoMetaTag={seoMetaTag}
        >
            <CustomForm
                key={key}
                endpoints={endpoints}
                layout={formLayoutData}
                editModel={seoMetaTag?.id ?? null}
                onChange={onChange}
            />

            <ContainerLoader visible={loader} />
        </SeoMetaTagFormModal>
    )
}


export const SeoMetaTagFormModal = ({visible, onHide, children, seoMetaTag}) => {

    const hideModel = () => {
        if(typeof onHide === 'function') {
            onHide(false)
        }
    }

    return (
        <CustomModal
            visible={visible}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h6 className="modal-title">
                        {
                            seoMetaTag ?
                                `Edit meta tag ${seoMetaTag?.name ?? ""}`
                                :
                                `Add new meta tag`
                        }
                    </h6>
                    <button aria-label="Close" className="close" type="button" onClick={hideModel}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className={"card"} style={{backgroundColor: "#ffffff3d"}}>
                        <div className={"card-body"}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}
