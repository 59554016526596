import React, {useEffect} from "react";
import {CustomImageInput} from "../../../UploadedImages/CustomImageInput";

export const CustomImageSelector = (props) => {
    const divCustomClasses = props.input.divCustomClasses ?? "col-md-6 mg-b-10";

    useEffect(() => {
        if(typeof props.onReady === 'function') {
            props.onReady()
        }
    }, []);

    return (
        <div className={divCustomClasses}>
            <label className="form-label">{props.input.title}</label>
            <CustomImageInput
                key={props.input.id}
                defaultValue={props.defaultValue ?? ""}
                multiple={props.multiple ?? false}
                onChange={(image) => props.onChange(props.input.id, image)}
            />
        </div>
    )
}
