import React, {useEffect, useState} from "react";
import {replaceSpecialCharsWithDash} from "../../../../helpers";

export const CustomTextArea = (props) => {
    const [value, setValue] = useState(props.defaultValue ?? "");
    const divCustomClasses = props.input.divCustomClasses ?? "col-md-12 mg-b-10";

    useEffect(() => {
        if (typeof props.onReady === 'function') {
            props.onReady()
        }
    }, []);

    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue])

    const onChange = (event) => {
        let value = event.target.value;

        setValue(value);
        props.onChange(props.input.id, value);
    }

    return (
        <div className={divCustomClasses}>
            <div className="form-group mg-b-0">
                <label className="form-label">{props.input.title}</label>
                <textarea
                    className={"form-control"}
                    placeholder={props.input.description}
                    defaultValue={value ?? ""}
                    onChange={onChange}
                />
                {'showCounts' in props.input && props.input.showCounts === true ?
                    <span className={"text-muted"}>
                        {value.length > 0 ? value.split(' ').length : 0} words and {value.length} characters and {value.length > 0 ? value.split(',').length : 0} comma seperated phrases
                    </span>
                    :
                    <></>
                }
            </div>
        </div>
    )
}
