import {CustomModal} from "../../Components/CustomModal";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {config} from "../../config";
import {CustomForm} from "../../Components/CustomForm/CustomForm";
import {CSSTransition} from "react-transition-group";

export const PurchaseLinksForm = ({purchaseLink = null, visible, onHide, onSuccess}) => {
    const endpoints = useMemo(() => {
        return {
            update: config.purchaseLinks.endpoints.update(purchaseLink?.id ?? null),
            edit: config.purchaseLinks.endpoints.find(purchaseLink?.id ?? null),
            store: config.purchaseLinks.endpoints.store,
            index: config.purchaseLinks.endpoints.index
        };
    }, [purchaseLink])

    return (
        purchaseLink ?
            <PurchaseLinkEditModal
                visible={visible}
                onHide={onHide}
                purchaseLink={purchaseLink}
                endpoints={endpoints}
                layout={config.purchaseLinks.customFormInputs(config.sellers.endpoints.get)}
                onSuccess={onSuccess}
            />
            :
            <PurchaseLinkNewModal
                visible={visible}
                onHide={onHide}
                endpoints={endpoints}
                layout={config.purchaseLinks.customFormInputs(config.sellers.endpoints.get)}
                onSuccess={onSuccess}
            />
    )
}

export const PurchaseLinkNewModal = ({visible, onHide, endpoints, layout, onSuccess}) => {
    const newPurchaseLinkFormRef = useRef();

    return (
        <CSSTransition
            in={visible}
            nodeRef={newPurchaseLinkFormRef}
            timeout={300}
            classNames="alert"
            unmountOnExit
        >
            <div ref={newPurchaseLinkFormRef} className={"row justify-content-center mt-2"}>
                <div className={"col-12"}>
                    <div className="card mg-b-20">
                        <div className="card-body">
                            <CustomForm
                                endpoints={endpoints}
                                layout={layout}
                                onSuccess={onSuccess}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export const PurchaseLinkEditModal = ({visible, onHide, endpoints, layout, purchaseLink, onSuccess}) => {

    const hideModel = () => {
        if (typeof onHide === 'function') {
            onHide(false)
        }
    }

    return (
        <CustomModal
            visible={visible}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h6 className="modal-title">
                        Edit purchase link ${purchaseLink?.name ?? ""}
                    </h6>
                    <button aria-label="Close" className="close" type="button" onClick={hideModel}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className={"card"} style={{backgroundColor: "#ffffff3d"}}>
                        <div className={"card-body"}>
                            <CustomForm
                                endpoints={endpoints}
                                layout={layout}
                                editModel={purchaseLink.id}
                                onSuccess={onSuccess}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}
