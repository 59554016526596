import React, {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import FeatherIcon from "feather-icons-react";
import {CollectionProductForm} from "../../CollectionProducts/CollectionProductForm";
import {getStatus} from "../../Services/CollectionService";
import {StatusButtons} from "./StatusButtons";
import {DeleteRestoreButton} from "./DeleteRestoreButton";
import {config} from "../../config";

export const ActionButtons = ({collection, setCollection}) => {
    const [newProductsModal, setNewProductsModal] = useState(false);


    return (
        <React.Fragment>
            <Tooltip title="Add product" arrow placement={"top"}>
                <button className="btn btn-primary btn-icon p-0 rounded ml-3"
                        onClick={() => setNewProductsModal(true)}>
                    <FeatherIcon icon={"plus-circle"}/>
                </button>
            </Tooltip>

            <StatusButtons collection={collection} setCollection={setCollection} />

            <Tooltip title="Edit" arrow placement={"top"}>
                <a href={config.collections.endpoints.create(collection.id)} className="btn btn-indigo btn-icon p-0 rounded ml-3">
                    <FeatherIcon icon={"edit"}/>
                </a>
            </Tooltip>

            <DeleteRestoreButton collection={collection} setCollection={setCollection} />

            <CollectionProductForm
                visible={newProductsModal}
                onHide={(value) => setNewProductsModal(value)}
                collection={collection}
            />
        </React.Fragment>
    )
}
