import React, {useEffect, useState} from "react";

export const CustomRadioInput = ({input, defaultValue, onChange}) => {
    const [value, setValue] = useState(null);
    const divCustomClasses = input.divCustomClasses ?? "col-md-6 mg-b-10";

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const onRadioBtnChanged = (event) => {
        setValue(event.target.value);
        if (typeof onChange === 'function') {
            onChange(input.id, event.target.value)
        }
    }

    const RadioOptions = () => {
        const options = input.options.map((option, index) => (
            <div className={""} key={index}>
                <label className="rdiobox">
                    <input
                        type="radio"
                        checked={value === option.value}
                        name={input.id}
                        value={option.value}
                        onChange={onRadioBtnChanged}
                    />
                    <span>{option.label}</span>
                </label>
            </div>
        ));

        return (<div className={"d-flex justify-content-start"} style={{gap: "20px"}}>{options}</div>);
    }

    return (
        <div className={divCustomClasses}>
            <div className="form-group mg-b-0">
                <label className="form-label">{input.title}</label>
                <RadioOptions />
            </div>
        </div>
    )
}
