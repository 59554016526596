import React, {useEffect, useState} from "react";

export const CustomSelect = (props) => {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(props.defaultValue ?? "");
    const [isReady, setIsReady] = useState(false);

    const divCustomClasses = props.input.divCustomClasses ?? "col-md-6 mg-b-10";

    useEffect(() => {
        if (props.input?.dataSource?.endpoint) {
            setOptions([{value: "", label: "Loading"}]);
            axios.get(props.input.dataSource.endpoint)
                .then(res => {
                    let inputOptions = res.data.map((item) => {
                        return {
                            value: props.input.dataSource.valueKey ? item[props.input.dataSource.valueKey] : item.id,
                            label: props.input.dataSource.labelKey ? item[props.input.dataSource.labelKey] : item.name
                        }
                    });


                    if(props.input.dataSource.filter) {
                        inputOptions = inputOptions.filter(item => item.value != props.input.dataSource.filter);
                    }

                    setOptions(inputOptions);
                    setIsReady(true);
                })
                .catch(err => {
                    setOptions([{value: "", label: "Error occurred"}]);
                })
        } else {
            const inputOptions = props.input.options;
            setOptions(props.input.options);

            setIsReady(true);
        }
    }, [props.input.options ?? "", props.input.dataSource ?? ""]);

    useEffect(() => {
        if(isReady && typeof props.onReady === 'function') {
            props.onReady()
        }
    }, [isReady]);

    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue])

    const onChange = (event) => {
        setValue(event.target.value);
        props.onChange(props.input.id, event.target.value)
    }

    const getSelectOptions = () => {
        const selectOptions = options.map((item, index) => <option key={index}
                                                                   value={item.value}>{item.label}</option>);

        return <React.Fragment>{selectOptions}</React.Fragment>
    }

    return (
        <div className={divCustomClasses}>
            <div className="form-group mg-b-0">
                <label className="form-label">{props.input.title}</label>
                <select
                    className="form-control"
                    value={value}
                    onChange={onChange}
                >
                    <option value={""}>{props.input.description}</option>
                    {getSelectOptions()}
                </select>
            </div>
        </div>
    )
}
