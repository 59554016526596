import React from "react";
import {config} from "../../config";
import {CustomSelect} from "../../Components/CustomForm/Inputs/CustomSelect";
import {CustomTextInput} from "../../Components/CustomForm/Inputs/CustomTextInput";

export default function SeoMetaTagsFilters({onChange, filters}) {

    const onChangeFilter = (key, value) => {
        if (typeof onChange === 'function') {
            onChange(key, value.length > 0 ? value : null);
        }
    }

    return (
        <div className={"mt-3 p-4 bg-white rounded-5"}>
            <div className={"row"}>
                <div className={"col-12"}><h4>Filter by:</h4></div>

                <CustomTextInput
                    onChange={onChangeFilter}
                    defaultValue={filters.name ?? ''}
                    input={{
                        id: 'name',
                        title: 'Tag name:',
                        description: 'Filter by tag name',
                        divCustomClasses: 'col-sm-3',
                    }}
                />

                <CustomTextInput
                    onChange={onChangeFilter}
                    defaultValue={filters.value ?? ''}
                    input={{
                        id: 'value',
                        title: 'Tag value:',
                        description: 'Filter by tag value',
                        divCustomClasses: 'col-sm-3',
                    }}
                />

                <CustomSelect
                    onChange={onChangeFilter}
                    input={{
                        id: 'tag_page',
                        title: 'Page:',
                        description: 'Filter by page',
                        divCustomClasses: 'col-sm-3',
                        options: config.seoMetaTags.pageSelector.pages
                    }}
                />

                <CustomTextInput
                    onChange={onChangeFilter}
                    defaultValue={filters.model ?? ''}
                    disabled={!filters.tag_page || !(filters.tag_page in config.seoMetaTags.pageSelector.models)}
                    input={{
                        id: 'model',
                        title: 'Page model:',
                        description: 'Filter by page model',
                        divCustomClasses: 'col-sm-3',
                    }}
                />
            </div>
        </div>
    )
}
