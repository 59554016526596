import {config} from "../../config"
import FeatherIcon from "feather-icons-react"
import {CustomModal} from "../../Components/CustomModal"
import {CustomPagination} from "../../Components/CustomPagination"
import useStats from "./useStats";
import {truncateString} from "../../../helpers";

export default function IpStats({filters = {}, count, onFilterChange}) {
    const {
        isOpen,
        handleOnOpen,
        handleOnClose,
        handleOnPageChange,
        page,
        isLoading,
        data,
        metaData,
        applyFilter
    } = useStats(filters, onFilterChange, config.dashboard.endpoints.stats + "/ip")

    return (
        <div className="card">
            <div className="card-body">
                <a href="#" className="d-flex justify-content-between align-items-center" onClick={handleOnOpen}>
                    <div>
                        <p className=" mb-0">Users</p>
                        <h3 className="mb-0">{count ?? 0}</h3>
                    </div>
                    <div className="ms-auto text-end">
                        <div className="bg-primary-transparent text-primary rounded-circle p-2">
                            <FeatherIcon icon={"users"}/>
                        </div>
                    </div>
                </a>

                <CustomModal visible={isOpen}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title">
                                Users
                            </h6>
                            <button aria-label="Close" className="close" type="button" onClick={handleOnClose}>
                                <span>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {isLoading ?
                                <div className="dimmer active">
                                    <div className="spinner1-lg">
                                        <div className="double-bounce1"></div>
                                        <div className="double-bounce2"></div>
                                    </div>
                                </div>
                                :
                                <div className="table-responsive text-nowrap w-100">
                                    <table className="table ">
                                        <thead>
                                        <tr className="bg-white">
                                            <th>#</th>
                                            <th>IP</th>
                                            <th>Country</th>
                                            <th>City</th>
                                            <th>ISP</th>
                                            <th className="text-center">Visits</th>
                                            <th className="text-center">Routes</th>
                                            <th>First Access</th>
                                            <th>Last Access</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(Array.isArray(data) ? data : []).map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + parseInt(metaData?.from ?? 1)}</td>
                                                <td>
                                                    <a href="#" onClick={applyFilter('ip', item.ip)}>
                                                        {item.ip}
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="#" onClick={applyFilter("country_code", item.country_code)}
                                                       className="d-inline-flex align-items-center mt-1">
                                                            <span className="avatar avatar-xs  tx-18">
                                                                {item?.country?.flag ?? ''}
                                                            </span>
                                                        <span
                                                            className="ms-2 fs-14 fw-semibold mb-0">{item?.country?.name ?? ''}</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="#"
                                                       onClick={applyFilter('city', item.city)}>
                                                        {item.city}
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="#"
                                                       onClick={applyFilter('isp', item.isp)}>
                                                        {truncateString(item.isp)}
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    {item.total}
                                                </td>
                                                <td className="text-center">
                                                    {item.routes}
                                                </td>
                                                <td>
                                                    {item.first_access}
                                                </td>
                                                <td>
                                                    {item.last_access}
                                                </td>

                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    <CustomPagination
                                        current={page}
                                        onPageChange={handleOnPageChange}
                                        perPage={metaData.perPage ?? 15}
                                        total={metaData.total ?? 0}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </CustomModal>
            </div>
        </div>
    )
}
