import React, {useMemo, useRef} from 'react';
import {config} from "../../config";
import {getObjValue} from "../../../helpers";

export const LayoutDataTable = ({inputs, data, draggable = false, onDragEnd = null}) => {
    const draggingItemRef = useRef();
    const placingDraggedItemRef = useRef();
    const onDragStart = index => draggingItemRef.current = index;
    const onDragOver = index => placingDraggedItemRef.current = index;

    const thead = useMemo(() => {
        return inputs.map((input, index) => (
            <th key={index}>{input.title}</th>
        ));
    }, [data, inputs]);


    const tbody = useMemo(() => {
        return data.map((value, valueIndex) => {
            const tds = inputs.map((input, inputIndex) => {

                return (
                    <td key={inputIndex}>
                        {(typeof input.value === 'function') ?
                            input.value(value, valueIndex)
                            :
                            <LayoutDataItem input={input} value={value[input.id] ?? null}/>
                        }
                    </td>
                )
            })

            return (
                <tr
                    key={valueIndex}
                    draggable={draggable}
                    onDragStart={() => onDragStart(valueIndex)}
                    onDragOver={() => onDragOver(valueIndex)}
                    onDragEnd={() => onDragEnd(draggingItemRef.current, placingDraggedItemRef.current)}
                    onDragOverCapture={(e) => e.preventDefault()}
                >
                    {tds}
                </tr>
            )
        })
    }, [data, inputs])

    return (
        <div className="table-responsive">
            <table className="table table-hover mg-b-10 text-md-nowrap bd">
                <thead>
                <tr>
                    {thead}
                </tr>
                </thead>
                <tbody>
                {tbody}
                </tbody>
            </table>
        </div>
    )
}

export const LayoutDataContainer = ({inputs, data, classNames = {}}) => {
    const rowBody = inputs.map((input, index) => {
        const divClass = classNames[input.id] ??
            input.customClassShow ??
            config.layoutDataContainer.classNamesByType[input.type] ??
            classNames.default ?? 'col-md-4';

        return (
            <div className={divClass} key={index}>
                <h6>{input.title}</h6>
                <LayoutDataItem input={input} value={getObjValue(data, input.id) ?? null}/>
            </div>
        )
    })

    return (
        <div className={classNames?.row ?? "row"}>
            {rowBody}
        </div>
    )
}

export const LayoutDataItem = ({input, value}) => {

    if (value && input.type === 'image') {
        return <p><a target={"_blank"} href={value.url}> {value.url} </a></p>
    } else if (value && input.type === 'multiple_images') {
        return (
            <p>
                {value.map(((image, index) => (
                    <a target={"_blank"} href={image.url} key={index}> {image.url} </a>
                )))}
            </p>
        )
    } else if (value && input.type === 'form_repeater') {
        return <LayoutDataTable inputs={input.inputs} data={value}/>
    } else if (value && input.type === 'rating') {
        return <p><strong>Score: </strong> {value.score ?? 'N/A'} <strong
            className={"ml-3"}>Text: </strong> {value.text ?? 'N/A'}</p>
    } else if (value && input.type === 'purchase_link') {
        return <p><span
            className={"badge badge-pill badge-primary ml-2"}>{`${value.name} - ${value.currency}${value.price} at ${value.seller}`}</span>
        </p>
    } else if (value && input.type === 'purchase_links') {
        return (
            <p>
                {value.map((link, index) => (
                    <span className={"badge badge-pill badge-primary ml-2"}
                          key={index}>{`${link.name} - ${link.currency}${link.price} at ${link.seller}`}</span>
                ))}
            </p>
        )
    } else if (value && input.type === 'pros_and_cons') {
        const pros = 'pros' in value && Array.isArray(value.pros) ? value.pros : [];
        const cons = 'cons' in value && Array.isArray(value.cons) ? value.cons : [];

        return (
            <LayoutDataTable
                inputs={[
                    {
                        title: 'pros',
                        value: (val, index) => pros.length > index ? pros[index] : '',
                    },
                    {
                        title: 'cons',
                        value: (val, index) => cons.length > index ? cons[index] : '',
                    }
                ]}
                data={pros.length > cons.length ? pros : cons}
            />
        )
    }

    return <p>{value ? String(value) : ""}</p>
}


