import React, {useEffect, useRef, useState} from "react";
import {IconButton} from "../../IconButton";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";
import {LayoutInputs} from "../LayoutInputs";

export const CustomFormRepeater = (props) => {
    const [values, setValues] = useState(props.defaultValue ?? []);
    const draggingItemRef = useRef();
    const placingDraggedItemRef = useRef();
    const onDragStart = index => draggingItemRef.current = index;
    const onDragOver = index => placingDraggedItemRef.current = index;

    const onDragEnd = () => {
        const draggedValues = [...values];
        const sortedItem = draggedValues.splice(draggingItemRef.current, 1)[0];
        draggedValues.splice(placingDraggedItemRef.current, 0, sortedItem)

        setValues(draggedValues)
    }

    useEffect(() => {
        if (typeof props.onReady === 'function') {
            props.onReady()
        }
    }, []);

    useEffect(() => {
        if(values.length < props.input.min) {
            for (let i = 0; i < (props.input.min - values.length); i++) {
                addNewRow();
            }
        }
    }, []);

    useEffect(() => {
        if (values.length && typeof props.onChange === 'function') {
            props.onChange(props.input.id, values);
        }
    }, [values])

    const addNewRow = () => {
        setValues((current) => {
            let newObj = {};
            for (let i in props.input.inputs) {
                newObj[props.input.inputs[i].id] = null;
            }

            return [...current, newObj];
        });
    }

    const onRemove = (i) => {
        setValues((current) => {
            if((props.input.min ?? 0) < current.length) {
                current.splice(i, 1);
            }
            return [...current];
        })
    }

    const onChange = (i, key, value) => {
        setValues((current) => {
            current[i][key] = value;
            return [...current];
        });
    }

    const repeaterInputs = () => {
        let inputs = values.map((item, index) => {
            return (
                <div
                    className={"row pos-relative bd-t bd-2 pd-t-10"} key={index}
                    draggable={true}
                    onDragStart={() => onDragStart(index)}
                    onDragOver={() => onDragOver(index)}
                    onDragEnd={onDragEnd}
                    onDragOverCapture={(e) => e.preventDefault()}
                >
                    <LayoutInputs
                        layout={{inputs: props.input.inputs}}
                        onChange={(key, value) => onChange(index, key, value)}
                        defaultValue={item}
                    />
                    <div className={"pos-absolute t-5 r-5"}>
                        <IconButton
                            className={"btn btn-danger rounded-30 p-0 justify-content-center wd-25-f ht-25-f"}
                            icon={'x-circle'}
                            tooltip={"Delete row"}
                            onClick={() => onRemove(index)}
                            loader={false}
                        />
                    </div>
                </div>

            )
        });

        return <React.Fragment>{inputs}</React.Fragment>;
    }

    return (
        <div className={"col-md-12 mg-b-10 bd bd-4 pd-t-10"}>
            <div className={"row"}>
                <div className={"col-12 d-flex justify-content-between align-items-center"}>
                    <div>
                        <h6>{props.input.title}</h6>
                        <p>{props.input.description}</p>
                    </div>
                    <div>
                        <Tooltip title="Add new row" arrow placement={"top"}>
                            <button className="btn btn-indigo btn-icon p-0 rounded ml-3" onClick={addNewRow}>
                                <FeatherIcon icon={"plus-circle"}/>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>

            {repeaterInputs()}
        </div>
    )
}
