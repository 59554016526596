import {config} from "../config";
import {CustomAlert, showToast} from "../Components/CustomAlerts";

export function getPromotions(successCallback, errorCallback, loaderCallback) {
    loaderCallback(true)
    axios.get(config.promotions.endpoints.get)
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function storePromotion(data, successCallback, errorCallback, loaderCallback) {
    loaderCallback(true)
    axios.post(config.promotions.endpoints.store, data)
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function updatePromotion(promotion, data, successCallback, errorCallback, loaderCallback) {
    loaderCallback(true)
    axios.patch(config.promotions.endpoints.update(promotion.id), data)
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function deletePromotion(promotion, successCallback, loaderCallback) {
    const deleteFunction = () => {
        axios.delete(config.promotions.endpoints.delete(promotion.id))
            .then((res) => {
                showToast("Promotion successfully deleted");
                successCallback(res.data);
            })
            .catch(err => console.error(err?.response?.data?.message ?? 'Error Occurred'))
            .finally(() => loaderCallback(false))
    }

    if (promotion.status === 'Removed') {
        CustomAlert(
            `Permanently Delete "${promotion.title}" promotion`,
            "Are you sure you want to <strong> permanently delete </strong> this promotion?",
            deleteFunction,
        );
    } else {
        deleteFunction();
    }
}

export function restorePromotion(slider, successCallback, loaderCallback) {
    loaderCallback(true)
    axios.put(config.promotions.endpoints.restore(slider.id))
        .then(res => {
            showToast("Promotion successfully restored");
            successCallback(res.data);
        })
        .catch(err => console.error(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function onPromotionDragEnd(promotions, sortedIndex, placedIndex) {
    let newPromotions = [...promotions];
    let sortedItem = newPromotions.splice(sortedIndex, 1)[0];
    newPromotions.splice(placedIndex, 0, sortedItem)
    return newPromotions;
}

export function saveSortedPromotions(promotions, successCallback, errorCallback, loaderCallback) {
    const data = promotions.map((item, index) => {
        return {id: item.id, sort: index}
    })

    loaderCallback(true)
    axios.put(config.promotions.endpoints.sort, {data: data})
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function getSelectedPromotionsForPage(successCallback, loaderCallback, page, model = null) {
    let params = {model: model};

    loaderCallback(true);
    axios.get(config.promotions.pageSelector.endpoints.get(page), {params: params})
        .then(res => successCallback(res.data ?? []))
        .catch(err => showToast(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false));
}

export function storeSelectedPromotionsForPage(successCallback, loaderCallback, promotions, page, model = null) {
    const formData = {
        model: model ?? null,
        promotions: promotions
    };

    loaderCallback(true);
    axios.patch(config.promotions.pageSelector.endpoints.patch(page), formData)
        .then(res => {
            successCallback(res.data ?? []);
            showToast("Saved successfully");
        })
        .catch(err => showToast(err?.response?.data?.message ?? 'Error Occurred', 'error'))
        .finally(() => loaderCallback(false));
}
