import $ from 'jquery';
const Mustache = require('mustache');
import {countriesResultTemplate, categoriesTemplate} from './templates'
class Selectors {

    render() {
        $('.select2').select2({
            placeholder: 'Choose one',
            searchInputPlaceholder: 'Search'
        });

        this.countries('countries-select2');
        this.countries('phoneCode-select2', {showFlag:true, showCode: true, showPhoneCode: true}, {showText: false});
        this.products();
        this.organizationCategory();
    }

    countries(id, templateConfig = {}, selectedConfig = {}) {
        let element = $('#'+id);
        if(element) {
            let defaultConfig = {
                showText: true,
                showFlag: false,
                showPhoneCode: false,
                showCode: false,
                emitEvent: 'countrySelected'
            }

            templateConfig = {...defaultConfig, ...templateConfig};
            selectedConfig = {...templateConfig, ...selectedConfig}

            let templates = {
                templateResult: countriesResultTemplate,
                templateSelection: countriesResultTemplate,
                configResult: templateConfig,
                configSelection: selectedConfig
            }

            element.select2(
                this.ajaxParams({url: '/countries', templates: templates, minimumInputLength: 0})
            );
        }
    }

    products() {
        let element = $('#products-select2');
        if(element) {
            element.select2(
                this.ajaxParams({url: '/products', multiple: true, tags: true})
            )
        }
    }

    organizationCategory() {
        let element = $('#select2-organization-category');

        if(element) {
            element.select2(
                this.ajaxParams({url: '/categories', minimumInputLength: 0})
            )
        }
    }

    ajaxParams = (config = {}) => {
        let params =  {
            placeholder: {
                id: null, // the value of the option
                text: config.placeholder ?? 'Select'
            },
            minimumInputLength: config.minimumInputLength ?? 2,
            multiple: config.multiple ?? false,
            tags: config.tags ?? false,
            ajax: {
                url: config.url,
                data: function(params) {
                    return {
                        term: params.term
                    }
                },
                processResults: function(data) {
                    return {results: data.data};
                }
            }
        }

        if(config.templates !== null && config.templates !== undefined) {
            let templates = config.templates;

            if(templates.templateResult !== null && templates.templateResult !== undefined) {
                params.templateResult = function(item) {
                    return $(Mustache.render(templates.templateResult, {config: config.templates.configResult, item: item}));
                }
            }

            if(templates.templateSelection !== null && templates.templateSelection !== undefined) {
                params.templateSelection = function(item) {
                    if(item.id == null) {
                        return item.text;
                    }

                    return $(Mustache.render(templates.templateSelection, {
                        config: templates.configSelection,
                        item: item
                    }));
                }
            }
        }



        return params;
    }

    addPreSelectedValues(element, url, selectedTemplate, config) {
        //console.log(element.val());
    }
}

let selectors = new Selectors();
export default selectors;
