import React, {useEffect, useState} from "react";
import {ContainerLoader} from "../Components/ContainerLoader";
import {SeoMetaTagsHeader} from "./Partials/SeoMetaTagsHeader";
import {SeoMetaTagForm} from "./Partials/SeoMetaTagForm";
import SeoMetaTagsTable from "./Partials/SeoMetaTagsTable";
import SeoMetaTagsFilters from "./Partials/SeoMetaTagsFilters";

export const SeoMetaTags = () => {
    const [loader, setLoader] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [editableMetaTag, setEditableMetaTag] = useState(null);
    const [filters, setFilters] = useState({});

    const onEdit = (item) => {
        setEditableMetaTag(item);
        setShowFormModal(true);
    }

    const onHideForm = () => {
        setEditableMetaTag(null);
        setShowFormModal(false);
    }

    const onChangeFilters = (key, value) => {
        setFilters((current) => {
            current[key] = value;

            if (key === 'page') {
                current['model'] = null;
            }

            return {...current};
        })
    }

    return (
        <div>
            <SeoMetaTagsHeader
                onAddNewClick={() => setShowFormModal(true)}
            />

            <SeoMetaTagsFilters
                filters={filters}
                onChange={onChangeFilters}
            />

            <SeoMetaTagsTable
                onEdit={onEdit}
                filters={filters}
                onPageChange={(page) => onChangeFilters('page', page)}
            />

            <SeoMetaTagForm
                visible={showFormModal}
                onHide={onHideForm}
                seoMetaTag={editableMetaTag}
            />

            <ContainerLoader visible={loader}/>
        </div>
    );
}
