const eva = require('eva-icons');
const feather = require('feather-icons');

(function (window, document, $) {
    'use strict'
    eva.replace();
    feather.replace();

    // ___________TOOLTIP
    $('[data-toggle="tooltip"]').tooltip();
    // colored tooltip
    $('[data-toggle="tooltip-primary"]').tooltip({
        template: '<div class="tooltip tooltip-primary" role="tooltip"><div class="arrow"><\/div><div class="tooltip-inner"><\/div><\/div>'
    });
    $('[data-toggle="tooltip-secondary"]').tooltip({
        template: '<div class="tooltip tooltip-secondary" role="tooltip"><div class="arrow"><\/div><div class="tooltip-inner"><\/div><\/div>'
    });

    // __________POPOVER
    $('[data-toggle="popover"]').popover();
    $('[data-popover-color="head-primary"]').popover({
        template: '<div class="popover popover-head-primary" role="tooltip"><div class="arrow"><\/div><h3 class="popover-header"><\/h3><div class="popover-body"><\/div><\/div>'
    });
    $('[data-popover-color="head-secondary"]').popover({
        template: '<div class="popover popover-head-secondary" role="tooltip"><div class="arrow"><\/div><h3 class="popover-header"><\/h3><div class="popover-body"><\/div><\/div>'
    });
    $('[data-popover-color="primary"]').popover({
        template: '<div class="popover popover-primary" role="tooltip"><div class="arrow"><\/div><h3 class="popover-header"><\/h3><div class="popover-body"><\/div><\/div>'
    });
    $('[data-popover-color="secondary"]').popover({
        template: '<div class="popover popover-secondary" role="tooltip"><div class="arrow"><\/div><h3 class="popover-header"><\/h3><div class="popover-body"><\/div><\/div>'
    });
    $(document).on('click', function(e) {
        $('[data-toggle="popover"],[data-original-title]').each(function() {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                (($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false // fix for BS 3.3.6
            }
        });
    });

    // __________MODAL
    // showing modal with effect
    $('.modal-effect').on('click', function(e) {
        e.preventDefault();
        var effect = $(this).attr('data-effect');
        $('#modaldemo8').addClass(effect);
    });
    // hide modal with effect
    $('#modaldemo8').on('hidden.bs.modal', function(e) {
        $(this).removeClass(function(index, className) {
            return (className.match(/(^|\s)effect-\S+/g) || []).join(' ');
        });
    });

    // ______________LOADER
    $("#loading").fadeOut("slow");


    // ______________ COVER IMAGE
    $(".cover-image").each(function() {
        var attr = $(this).attr('data-image-src');
        if (typeof attr !== typeof undefined && attr !== false) {
            $(this).css('background', 'url(' + attr + ') center center');
        }
    });

    // ______________ CARD REMOVE
    $('[data-toggle="card-remove"]').on('click', function(e) {
        let $card = $(this).closest(DIV_CARD);
        $card.remove();
        e.preventDefault();
        return false;
    });


    // ______________Headerfixed
    $(window).on("scroll", function(e){
        if ($(window).scrollTop() >= 62) {
            $('.main-header').addClass('fixed-header');
            $('.main-header').addClass('visible-title');
            $('.header-main').addClass('headermain-fixed');
        }
        else {
            $('.main-header').removeClass('fixed-header');
            $('.main-header').removeClass('visible-title');
            $('.header-main').removeClass('headermain-fixed');
        }
    });

    // ______________ NAVBAR LEFT IN MOBILE
    $('#mainNavShow').on('click', function(e) {
        e.preventDefault();
        $('body').addClass('main-navbar-show');
    });

    // ______________  HIDE CURRENT OPEN CONTENT
    $('#mainContentLeftShow').on('click touch', function(e) {
        e.preventDefault();
        $('body').addClass('main-content-left-show');
    });

    // ______________  LEFT CONTENT SHOW IN MOBILE
    $('#mainContentLeftHide').on('click touch', function(e) {
        e.preventDefault();
        $('body').removeClass('main-content-left-show');
    });

    // ______________ CONTENT BODY IN MOBILE
    $('#mainContentBodyHide').on('click touch', function(e) {
        e.preventDefault();
        $('body').removeClass('main-content-body-show');
    })

    // ______________ NAVBAR BACKDROP MOBILE
    $('body').append('<div class="main-navbar-backdrop"></div>');
    $('.main-navbar-backdrop').on('click touchstart', function() {
        $('body').removeClass('main-navbar-show');
    });


    $('.main-header .dropdown > a').on('click', function(e) {
        e.preventDefault();
        $(this).parent().toggleClass('show');
        $(this).parent().siblings().removeClass('show');
    });

    // ______________ CLOSE DROPDOWN HEADERMENU
    $(document).on('click touchstart', function(e) {
        e.stopPropagation();
        var dropTarg = $(e.target).closest('.main-header .dropdown').length;
        if (!dropTarg) {
            $('.main-header .dropdown').removeClass('show');
        }
        if (window.matchMedia('(min-width: 992px)').matches) {
            var navTarg = $(e.target).closest('.main-navbar .nav-item').length;
            if (!navTarg) {
                $('.main-navbar .show').removeClass('show');
            }
            var menuTarg = $(e.target).closest('.main-header-menu .nav-item').length;
            if (!menuTarg) {
                $('.main-header-menu .show').removeClass('show');
            }
            if ($(e.target).hasClass('main-menu-sub-mega')) {
                $('.main-header-menu .show').removeClass('show');
            }
        } else {
            if (!$(e.target).closest('#mainMenuShow').length) {
                var hm = $(e.target).closest('.main-header-menu').length;
                if (!hm) {
                    $('body').removeClass('main-header-menu-show');
                }
            }
        }
    });
    $('#mainMenuShow').on('click', function(e) {
        e.preventDefault();
        $('body').toggleClass('main-header-menu-show');
    })
    $('.main-header-menu .with-sub').on('click', function(e) {
        e.preventDefault();
        $(this).parent().toggleClass('show');
        $(this).parent().siblings().removeClass('show');
    })
    $('.main-header-menu-header .close').on('click', function(e) {
        e.preventDefault();
        $('body').removeClass('main-header-menu-show');
    })

    $(".main-notification-list").mCustomScrollbar({
        theme:"minimal",
        autoHideScrollbar: true,
        scrollbarPosition: "outside"
    });
    $(".m-scroll").mCustomScrollbar({
        theme:"minimal",
        autoHideScrollbar: true,
        scrollbarPosition: "outside"
    });
    $("#mainContactList").mCustomScrollbar({
        theme:"minimal",
        autoHideScrollbar: true,
        scrollbarPosition: "outside"
    });
})(window, document, jQuery)
