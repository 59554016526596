import React, {useEffect, useMemo, useState} from "react";
import {config} from "../config";
import "react-slideshow-image/dist/styles.css";
import Tooltip from "@mui/material/Tooltip";
import FeatherIcon from "feather-icons-react";
import {CollectionProductForm} from "./CollectionProductForm";
import {CollectionProductModal} from "./CollectionProductModal";
import {LayoutDataTable} from "../Components/CustomForm/LayoutDataTable";
import {CustomAlert, showToast} from "../Components/CustomAlerts";
import {ContainerLoader} from "../Components/ContainerLoader";
import {sortCollectionProducts} from "../Services/CollectionService";

export const CollectionProducts = ({collection}) => {
    const [products, setProducts] = useState([]);
    const [editableProduct, setEditableProduct] = useState(null);
    const [viewableProduct, setViewableProduct] = useState(null);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getProducts();
    }, [collection.id]);

    const getProducts = () => {
        setLoader(true)

        axios.get(config.collectionProducts.endpoints.get(collection.id))
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => {
                setError(err?.response?.data?.message ?? 'Error Occurred');
            })
            .finally(() => {
                setLoader(false)
            })
    }

    const onDelete = (product) => {
        CustomAlert(
            `Delete ${product.name}`,
            `Are you sure you want to ${product.status !== 'Active' ? '<strong>permanently delete</strong>' : 'remove'} this product?`,
            () => {
                setLoader(true);

                axios.delete(config.collectionProducts.endpoints.delete(collection.id, product.id))
                    .then((res) => {
                        getProducts();
                        showToast(`Product  ${product.status !== 'Active' ? '<strong>permanently delete</strong>' : 'remove'}`, 'success');
                    })
                    .catch(() => {
                        showToast("Can not delete this product", 'error');
                        setLoader(false);
                    })
            },
            null,
            {
                icon: 'question'
            }
        );
    }

    const onRestore = (product) => {
        axios.put(config.collectionProducts.endpoints.restore(collection.id, product.id))
            .then((res) => {
                getProducts();
                showToast(`${product.name} product restored successfully`, 'success');
            })
            .catch(() => {
                showToast("Can not restore this product", 'error');
                setLoader(false);
            })
    }

    const onDragEnd = (draggedIndex, placedIndex) => {
        const draggedProducts = [...products];
        const sortedItem = draggedProducts.splice(draggedIndex, 1)[0];
        draggedProducts.splice(placedIndex, 0, sortedItem)

        setProducts(draggedProducts)
        sortCollectionProducts(collection, draggedProducts, setProducts, setLoader);
    }

    const inputs = useMemo(() => {
        return [
            {
                id: "name",
                type: "text",
                title: "Product name:"
            },
            {
                id: "details",
                type: "text",
                title: "Product details:"
            },
            {
                id: "textual_id",
                type: "text",
                title: "Textual ID:"
            },
            {
                id: "layout_name",
                type: "text",
                title: "Layout",
                value: (item) => item.layout?.title ?? "NA"
            },
            {
                id: "status",
                type: "text",
                title: "Status",
                value: (item) => <span
                    className={`badge badge-pill badge-${item.status === 'Active' ? 'success' : 'danger'}`}>{item.status}</span>
            },
            {
                id: "actions",
                type: "buttons",
                title: "Actions",
                value(item) {
                    return (
                        <div className={"d-flex"}>
                            <Tooltip title="View" arrow placement={"top"}>
                                <button className="btn btn-primary btn-icon p-0 rounded"
                                        onClick={() => setViewableProduct(item)}>
                                    <FeatherIcon icon={"eye"}/>
                                </button>
                            </Tooltip>
                            <Tooltip title="Edit" arrow placement={"top"}>
                                <button className="btn btn-indigo btn-icon p-0 rounded ml-3"
                                        onClick={() => setEditableProduct(item)}>
                                    <FeatherIcon icon={"edit"}/>
                                </button>
                            </Tooltip>
                            <Tooltip title={item.status === 'Active' ? "Remove" : "Permanently Delete"} arrow
                                     placement={"top"}>
                                <button className="btn btn-danger btn-icon p-0 rounded ml-3"
                                        onClick={() => onDelete(item)}>
                                    <FeatherIcon icon={"trash"}/>
                                </button>
                            </Tooltip>
                            {item.status === 'Removed' &&
                            <Tooltip title="Restore" arrow placement={"top"}>
                                <button className="btn btn-info btn-icon p-0 rounded ml-3"
                                        onClick={() => onRestore(item)}>
                                    <FeatherIcon icon={"rotate-ccw"}/>
                                </button>
                            </Tooltip>
                            }
                        </div>
                    )
                }
            }
        ]
    }, [])

    return (
        <div className={"card"}>
            <div className={"card-body"}>
                <h5>Collection products</h5>
                <LayoutDataTable
                    inputs={inputs}
                    data={products}
                    draggable={true}
                    onDragEnd={onDragEnd}
                />
            </div>

            <CollectionProductForm
                visible={editableProduct !== null}
                onHide={() => setEditableProduct(null)}
                product={editableProduct}
                collection={collection}
            />

            <CollectionProductModal
                visible={viewableProduct !== null}
                onHide={() => setViewableProduct(null)}
                product={viewableProduct}
                collection={collection}
            />

            <ContainerLoader visible={loader}/>
        </div>
    )
}
