import FeatherIcon from "feather-icons-react";
import React from "react";

export const HomeSliderHeader = ({onAddNewClick, sortingButtons, onSaveSorting, onResetSorting}) => {
    return (
        <div className="main-content-header p-4 bg-white mt-2 rounded-5">
            <div>
                <h6 className="main-content-title tx-14 mg-b-5 mg-t-3">Home Slider Images</h6>
                <p className="main-content-text tx-13 mg-b-0">
                    You can add, sort, or edit a home slider images here.
                </p>
            </div>
            <div className="main-content-header-right sub-headerstyle">

                {sortingButtons &&
                <>
                    <button className="btn btn-danger rounded-5 d-flex align-items-center justify-content-center"
                            onClick={onResetSorting}>
                        <FeatherIcon icon={"rotate-ccw"} size={18}/> <span className={"ml-2"}>Reset sorting</span>
                    </button>

                    <button className="btn btn-success rounded-5 d-flex align-items-center justify-content-center"
                            onClick={onSaveSorting}>
                        <FeatherIcon icon={"save"} size={18}/> <span className={"ml-2"}>Save sorting</span>
                    </button>
                </>
                }

                <button className="btn btn-primary rounded-5 d-flex align-items-center justify-content-center"
                        onClick={onAddNewClick}>
                    <FeatherIcon icon={"plus"} size={18}/> <span className={"ml-2"}>Add new slider</span>
                </button>
            </div>
        </div>
    )
}
