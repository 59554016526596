import React, {useState} from "react";
import FeatherIcon from "feather-icons-react";
import {LayoutDataContainer} from "../../Components/CustomForm/LayoutDataTable";
import {ActionButtons} from "./ActionButtons";

export const PromotionItem = ({item, onDragStart, onDragOver, onDragEnd, onEdit, setPromotions}) => {
    const [draggable, setDraggable] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const onShowMore = (e) => {
        e.preventDefault();
        setShowMore(current => !current);
    }

    return (
        <div
            className="col-12"
            draggable={draggable}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            onDragOverCapture={(e) => e.preventDefault()}
        >
            <div className="card mt-3 d-flex flex-row justify-content-between">
                <div className="card-body">
                    <div className={"row"}>
                        <div className={"col-md-3"}>
                            <h6>Title</h6>
                            <p>{item.title}</p>
                        </div>
                        <div className={"col-md-3"}>
                            <h6>Layout</h6>
                            <p>{item.layout.title}</p>
                        </div>
                        <div className={"col-md-3"}>
                            <h6>Status</h6>
                            <p className={`badge badge-pill badge-${item.status === 'Active' ? 'primary' : 'danger'}`}>{item.status}</p>
                        </div>
                        <div className={"col-md-3"}>
                            <h6>Actions</h6>
                            <ActionButtons
                                promotion={item}
                                onEdit={onEdit}
                                setPromotions={setPromotions}
                            />
                        </div>
                    </div>

                    <div className={"row mb-3"}>
                        <div className={"col-12"}>
                            <a href={"#"} onClick={onShowMore}>
                                {showMore ? 'Hide more' : 'Show more'}
                            </a>
                        </div>
                    </div>

                    {showMore &&
                    <LayoutDataContainer
                        inputs={item.layout.inputs ?? []}
                        classNames={{default: 'col-md-3'}}
                        data={item.layout_data ?? {}}
                    />
                    }
                </div>
                <div className={"d-flex align-items-center"}>
                    <div
                        className={"sortable-icon mr-2"}
                        onMouseDown={() => setDraggable(true)}
                        onMouseUp={() => setDraggable(false)}
                        onMouseLeave={() => setDraggable(false)}
                    >
                        <FeatherIcon
                            icon={"align-justify"}
                            color={"#005995"}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}
