import {CustomModal} from "../../Components/CustomModal";
import React from "react";
import {config} from "../../config";
import {CustomForm} from "../../Components/CustomForm/CustomForm";

export const HomeSliderForm =  ({homeSlider = null, visible, onHide}) => {
    const endpoints = {
        layouts: config.homeSlider.endpoints.getLayouts,
        update: config.homeSlider.endpoints.update(homeSlider?.id ?? null),
        edit: config.homeSlider.endpoints.find(homeSlider?.id ?? null),
        store: config.homeSlider.endpoints.store,
        index: config.homeSlider.endpoints.index
    };

    return (
        <HomeSliderFormModal
            visible={visible}
            onHide={onHide}
            homeSlider={homeSlider}
        >
            <CustomForm
                endpoints={endpoints}
                layout={config.homeSlider.customFormInputs(config.homeSlider.endpoints.getLayouts)}
                editModel={homeSlider?.id ?? null}
            />
        </HomeSliderFormModal>
    )
}


export const HomeSliderFormModal = ({visible, onHide, children, homeSlider}) => {

    const hideModel = () => {
        if(typeof onHide === 'function') {
            onHide(false)
        }
    }

    return (
        <CustomModal
            visible={visible}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h6 className="modal-title">
                        {
                            homeSlider ?
                                `Edit slider ${homeSlider?.title ?? ""}`
                                :
                                `Add new slider`
                        }
                    </h6>
                    <button aria-label="Close" className="close" type="button" onClick={hideModel}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className={"card"} style={{backgroundColor: "#ffffff3d"}}>
                        <div className={"card-body"}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}
