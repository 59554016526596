import React, {useState, useRef} from "react";
import FeatherIcon from "feather-icons-react";
import {CSSTransition} from 'react-transition-group';
import {UploadImageNewFile} from "./UploadImageNewFile";
import {SearchUploadedImages} from "./SearchUploadedImages";

export const UploadImageHeader = (props) => {
    const [newImageContainer, setNewImageContainer] = useState(false);
    const [searchContainer, setSearchContainer] = useState(false);
    const newImageContainerRef = useRef(null);
    const searchContainerRef = useRef(null);

    const showNewImageContainer = () => {
        setNewImageContainer(true);
        setSearchContainer(false);
    }

    const showSearchContainer = () => {
        setNewImageContainer(false);
        setSearchContainer(true);
    }

    const resetState = () => {
        setNewImageContainer(false);
        setSearchContainer(false);
    }

    return (
        <React.Fragment>
            <div className="main-content-header p-4 bg-white mt-2 rounded-5">
                <div>
                    <h6 className="main-content-title tx-14 mg-b-5 mg-t-3">Uploaded images</h6>
                    <p className="main-content-text tx-13 mg-b-0">All uploaded images are listed below.</p>
                </div>
                <div className="main-content-header-right sub-headerstyle">
                    <button className="btn btn-outline-primary rounded-5" disabled={searchContainer}
                            onClick={showSearchContainer}>
                        <FeatherIcon icon={'search'}/> Search Images
                    </button>

                    <button className="btn btn-primary rounded-5" disabled={newImageContainer}
                            onClick={showNewImageContainer}>
                        <FeatherIcon icon={'plus'}/> Upload new image
                    </button>
                </div>
            </div>

            <CSSTransition
                in={newImageContainer}
                nodeRef={newImageContainerRef}
                timeout={300}
                classNames="alert"
                unmountOnExit
            >
                <div ref={newImageContainerRef} className={"row justify-content-center mt-2"}>
                    <UploadImageNewFile
                        onResetState={resetState}
                        onUploadComplete={props.onUploadComplete ?? null}
                    />
                </div>
            </CSSTransition>

            <CSSTransition
                in={searchContainer}
                nodeRef={searchContainerRef}
                timeout={300}
                classNames="alert"
                unmountOnExit
            >
                <div ref={searchContainerRef} className={"row justify-content-center mt-2"}>
                    <SearchUploadedImages
                        onResetState={resetState}
                        onSearchSubmitted={props.onSearchSubmitted ?? null}
                    />
                </div>
            </CSSTransition>

        </React.Fragment>
    )
}
