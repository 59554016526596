import {ActionButtons} from "./ActionButtons";
import React, {useEffect, useState} from "react";
import {getSeoMetaTags} from "../../Services/SeoMetaTagService";
import {ContainerLoader} from "../../Components/ContainerLoader";
import {CustomPagination} from "../../Components/CustomPagination";
import {ComponentLoader} from "../../Components/ComponentLoader";

export default function SeoMetaTagsTable({onEdit, filters, onPageChange}) {
    const [seoMetaTags, setSeoMetaTags] = useState([]);
    const [metaData, setMetaData] = useState({});
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);

    const onSeoMetaTagsLoaded = (data) => {
        setSeoMetaTags(data.data);
        setMetaData(data.metadata);
    }

    useEffect(() => getSeoMetaTags(onSeoMetaTagsLoaded, setError, setLoader, filters), [filters])

    return (
        <>
            <div className={"mt-3 mb-3 p-4 bg-white rounded-5 position-relative"}>
                <div className="row justify-content-center mt-3">
                    <div className={"col-12"}>
                        <table className={"table table-bordered"}>
                            <thead>
                            <tr>
                                <th>No.</th>
                                <th>Name</th>
                                <th>Content</th>
                                <th>Page</th>
                                <th>Model</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                seoMetaTags.map((item, index) => (
                                    <tr key={index}>
                                        <td className={"wd-50"}>{(metaData.from ?? 0) + index}</td>
                                        <td>{item.name}</td>
                                        <td className={"wd-500"}>{item.value}</td>
                                        <td>{item.page}</td>
                                        <td>{item.model_name ?? ''}</td>
                                        <td>
                                            <ActionButtons
                                                metaTag={item}
                                                onEdit={() => onEdit(item)}
                                                setMetaTags={onSeoMetaTagsLoaded}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        <span className={"text-muted"}>
                            Showing <strong>{metaData.from ?? 0}</strong>-<strong>{metaData.to ?? 0}</strong> of {metaData.total ?? 0} records
                        </span>
                    </div>
                </div>

                <ComponentLoader visible={loader} />
            </div>

            <div className={"row"}>
                <CustomPagination
                    current={filters?.page ?? 1}
                    onPageChange={onPageChange}
                    perPage={filters?.perPage ?? metaData?.perPage ?? 10}
                    total={metaData.total ?? 0}
                />
            </div>
        </>
    )
}
