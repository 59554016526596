import FeatherIcon from "feather-icons-react";
import React, {useEffect, useRef, useState} from "react";
import {HomeSliderHeader} from "./Partials/HomeSliderHeader";
import {HomeSliderForm} from "./Partials/HomeSliderForm";
import {getSliders, onSliderDragEnd, saveSortedItems} from "../Services/HomeSliderService";
import {HomeSliderItem} from "./Partials/HomeSliderItem";
import {ContainerLoader} from "../Components/ContainerLoader";

export const HomeSlider = () => {
    const [homeSliders, setHomeSliders] = useState([]);
    const [sortableSliders, setSortableSliders] = useState([]);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [showFormModal, setShowFormModal] = useState(false);
    const [editableHomeSlider, setEditableHomeSlider] = useState(null);

    const getSlidersSuccess = (sliders) => {
        setHomeSliders(sliders);
        setSortableSliders(sliders);
    }

    useEffect(() => getSliders(getSlidersSuccess, setError, setLoader), []);

    const draggingItemRef = useRef();
    const placingDraggedItemRef = useRef();

    const onShowFormModal = () => setShowFormModal(true);
    const onDragStart = index => draggingItemRef.current = index;
    const onDragOver = index => placingDraggedItemRef.current = index;
    const onDragEnd = () => setSortableSliders(currentSliders => onSliderDragEnd(currentSliders, draggingItemRef.current, placingDraggedItemRef.current))

    const onResetSorting = () => setSortableSliders(() => [...homeSliders]);
    const onSaveSorting = () => saveSortedItems(sortableSliders, getSlidersSuccess, setError, setLoader);

    const onEdit = (item) => {
        setEditableHomeSlider(item);
        setShowFormModal(true);
    }

    const onHideForm = () => {
        setEditableHomeSlider(null);
        setShowFormModal(false);
    }

    const showSortingButtons = () => {
        for (let i = 0; i < homeSliders.length; i++) {
            if (homeSliders[i].id !== sortableSliders[i].id) {
                return true;
            }
        }

        return false;
    }

    return (
        <div>
            <HomeSliderHeader
                sortingButtons={showSortingButtons()}
                setHomeSliders={getSlidersSuccess}
                onResetSorting={onResetSorting}
                onSaveSorting={onSaveSorting}
                onAddNewClick={onShowFormModal}
            />

            <div className="row justify-content-center mt-3">
                {
                    sortableSliders.map((item, index) => (
                        <HomeSliderItem
                            key={index}
                            item={item}
                            onDragStart={() => onDragStart(index)}
                            onDragOver={() => onDragOver(index)}
                            onDragEnd={() => onDragEnd()}
                            setHomeSliders={getSlidersSuccess}
                            onEdit={() => onEdit(item)}
                        />
                    ))
                }
            </div>

            <HomeSliderForm
                visible={showFormModal}
                onHide={onHideForm}
                homeSlider={editableHomeSlider}
            />

            <ContainerLoader visible={loader}/>
        </div>
    );
}
