import React, {forwardRef, useImperativeHandle, useEffect, useState} from "react";
import {replaceSpecialCharsWithDash} from "../../../../helpers";

export const CustomTextInput = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.defaultValue ?? "");
    const divCustomClasses = props.input.divCustomClasses ?? "col-md-6 mg-b-10";

    useEffect(() => {
        if(typeof props.onReady === 'function') {
            props.onReady()
        }
    }, []);

    useEffect(() => {
       setValue(props.defaultValue);
    }, [props.defaultValue])

    const onChange = (event) => {
        let value = event.target.value;

        if(props.input.id === 'textual_id') {
            value = replaceSpecialCharsWithDash(value);
        }

        setValue(value);
        props.onChange(props.input.id, value);
    }

    useImperativeHandle(ref, () => ({
        setInputValue(newValue) {
            setValue(newValue);
        },
        id: props.input.id
    }));


    return (
        <div className={divCustomClasses}>
            <div className="form-group mg-b-0">
                <label className="form-label">{props.input.title}</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder={props.input.description}
                    value={value}
                    disabled={props.disabled === true}
                    onChange={onChange}
                />

            </div>
        </div>
    )
})
