import React, {useState} from "react";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";
import {PurchaseLinkStatusUpdate} from "./PurchaseLinkStatusUpdate";

export const ActionButtons = ({api, purchaseLink, onEdit, onUpdated}) => {
    const [updateStatusModal, setUpdateStatusModal] = useState(false);

    return (
        <div className={"d-flex justify-content-start"}>
            <Tooltip title="Edit purchase link" arrow placement={"top"}>
                <button className="btn btn-indigo btn-icon p-0 rounded"
                        onClick={onEdit}>
                    <FeatherIcon icon={"edit"}/>
                </button>
            </Tooltip>

            <Tooltip title="Update status" arrow placement={"top"}>
                <button
                    className={`btn btn-${purchaseLink.status.toLowerCase() === 'active' ? 'danger' : 'primary'} btn-icon p-0 rounded ml-3`}
                    onClick={() => setUpdateStatusModal(true)}>
                    <FeatherIcon icon={purchaseLink.status.toLowerCase() === 'active' ? 'x-circle' : 'check-circle'}/>
                </button>
            </Tooltip>

            <PurchaseLinkStatusUpdate
                purchaseLink={purchaseLink}
                visible={updateStatusModal}
                onHide={() => setUpdateStatusModal(false)}
                api={api}
                onUpdated={onUpdated}
            />
        </div>
    )
}


