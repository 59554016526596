import {showToast} from "../Components/CustomAlerts";

export default class ApiService {
    constructor(endpoints) {
        this.endpoints = endpoints;
    }

    setEndpoints(endpoints) {
        this.endpoints = {...this.endpoints, ...endpoints};
    }

    get(onSuccess, onError, onLoading, filters = {}, action = 'get') {
        const endpoint = this.getEndpoint(action);
        if (endpoint) {
            this.onLoading(onLoading, true);

            axios.get(endpoint, {params: filters})
                .then(res => this.onSuccess(onSuccess, res))
                .catch(err => this.onError(onError, err))
                .finally(() => this.onLoading(onLoading, false))
        }
    }

    store(data, onSuccess, onError, onLoading, action = 'store') {
        const endpoint = this.getEndpoint(action);
        if (endpoint) {
            this.onLoading(onLoading, true);

            axios.post(endpoint, data)
                .then(res => this.onSuccess(onSuccess, res))
                .catch(err => this.onError(onError, err))
                .finally(() => this.onLoading(onLoading, false));
        }
    }

    update(data, onSuccess, onError, onLoading, action = 'update', model = null) {
        const endpoint = this.getEndpoint(action, model);

        if (endpoint) {
            this.onLoading(onLoading, true);

            axios.patch(endpoint, data)
                .then(res => this.onSuccess(onSuccess, res))
                .catch(err => this.onError(onError, err, true))
                .finally(() => this.onLoading(onLoading, false));
        }
    }

    put(data, onSuccess, onError, onLoading, action = 'update', model = null) {
        const endpoint = this.getEndpoint(action, model);
        console.log(endpoint);
        if (endpoint) {
            this.onLoading(onLoading, true);

            axios.put(endpoint, data)
                .then(res => this.onSuccess(onSuccess, res))
                .catch(err => this.onError(onError, err, true))
                .finally(() => this.onLoading(onLoading, false));
        }
    }

    onSuccess(callback, res, toastMessage = null) {
        if (typeof callback === 'function') {
            callback(res.data)
        }

        if (typeof toastMessage === 'string') {
            showToast(toastMessage);
        }
    }

    onError(callback, err, toastMessage = false) {
        if (typeof callback === 'function') {
            callback(err?.response?.data?.message ?? 'Error Occurred')
        }

        console.log(err);
        if (toastMessage) {
            const message = typeof toastMessage === 'string' ? toastMessage :
                (err?.response?.data?.message ?? 'Error Occurred');
            showToast(message, 'error');
        }
    }

    onLoading(callback, loader = false) {
        if (typeof callback === 'function') {
            callback(loader);
        }
    }

    getEndpoint(action, model = null) {
        if (action in this.endpoints) {
            if (typeof this.endpoints[action] === 'string') {
                return this.endpoints[action];
            } else if (typeof this.endpoints[action] === 'function') {
                return this.endpoints[action](model?.id ?? null);
            }
        }

        console.warn(action + 'endpoint is not specified');
        return null;
    }
}
