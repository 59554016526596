import Chart from "react-apexcharts"
import useHistoryGraph from "./useHistoryGraph";

export default function HistoryGraph({filters}) {
    const {periods, series} = useHistoryGraph(filters)

    return (
        <div className="col-md-6 mb-3">
            <div className="card custom-card h-100">
                <div className="card-header justify-content-between">
                    <div className="card-title mb-0">
                        Visits History
                    </div>
                </div>
                <div className="card-body p-0">
                    <Chart
                        type="bar"
                        height="400px"
                        options={{
                            chart: {
                                id: "basic-bar"
                            },
                            xaxis: {
                                categories: periods
                            },

                            dataLabels: {
                                enabled: false
                            },
                        }}
                        series={series}
                    />
                </div>
            </div>
        </div>
    )
}
