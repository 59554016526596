import React, {useEffect, useState} from "react";
import {CustomModal} from "../Components/CustomModal";
import {PurchaseLinks} from "./PurchaseLinks";

export const PurchaseLinkPicker = ({selectedOptions, onChange, onHide, multiple = false, visible = false}) => {

    const onItemClicked = ((item, isSelected) => {
        let result;
        if (!multiple) {
            result = selectedOptions && selectedOptions.id === item.id ? null : item;

            if (result !== null) {
                onHide();
            }
        } else {
            result = Array.isArray(selectedOptions) ? [...selectedOptions] : [];
            result = isSelected ? result.filter(i => i.id !== item.id) : [...result, item];
        }

        if (typeof onChange === 'function') {
            onChange(result);
        }
    });

    return (
        <div className={"col-md-6"}>
            <CustomModal
                visible={visible}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title">Select / Add Purchase Link</h6>
                        <button aria-label="Close" className="close" type="button" onClick={onHide}>
                            <span>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <PurchaseLinks
                            selectable={true}
                            multiple={multiple}
                            selectedOptions={selectedOptions}
                            onItemSelected={onItemClicked}
                        />
                    </div>

                    <div className="modal-footer d-flex justify-content-end">
                        {multiple ?
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={onHide}
                            >
                                Done
                            </button>
                            :
                            <></>
                        }

                    </div>
                </div>
            </CustomModal>
        </div>
    )
}
