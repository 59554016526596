import {config} from "./react/config";

export const setObjValue = (obj, key, value) => {
    let array = key.split('.');

    if (array.length > 1) {
        let firstKey = array.shift();
        obj[firstKey] = setObjValue(obj[firstKey] ?? {}, array.join('.'), value);
    } else if (array.length === 1) {
        obj[key] = value;
    }

    return obj;
}

export const getObjValue = (obj, key) => {
    let array = key.split('.');
    if(typeof obj == 'object' && array.length > 1) {
      let firstKey = array.shift();
      return getObjValue(obj[firstKey] ?? {}, array.join('.'));
    }

    return  (typeof obj == 'object' && key in obj )? obj[key] : null;
}

export const replaceSpecialCharsWithDash = (value) => {
    return value.replace(/[^A-Z0-9]+/ig, "-").toLowerCase();
}

export const areIdenticalArrays = (firstArray, secondArray) => {
    for (let i = 0; i < firstArray.length; i++) {
        if (firstArray[i].id !== (secondArray[i]?.id ?? null)) {
            return false;
        }
    }

    return true;
}

export const areSamArrayList = (firstArray, secondArray) => {
    if(firstArray.length !== secondArray.length) {
        return false;
    }

    for(let i = 0; i < firstArray.length; i++) {
        if(secondArray.indexOf(firstArray[i]) === -1) {
            return false;
        }
    }

    return true;
}


export const addCustomAttributeRepeaterToLayout = (layout) => {
    if(layout.custom_attributes?.enabled === true) {
        layout.inputs.push(config.customAttributeRepeaterForm(layout.custom_attributes ?? {}))
    }

    return layout;
}


export const addCollectionCustomAttributeProductInputs = (collection, inputs, prefix = 'layout_data.', oneFromLastIndex = true) => {
    const newInputs = {...inputs};

    const customAttributes = collection?.layout?.custom_attributes ?? null;
    const layoutData = collection?.layout_data ?? {};

    if(customAttributes && customAttributes.enabled === true && customAttributes.id) {
        const idKey = customAttributes.id;
        const customAttributesInputs = transformCustomAttributes(layoutData[idKey] ?? [], `${prefix}${idKey}.`);

        if(customAttributesInputs.length) {
            if(oneFromLastIndex) {
                const lastItem = newInputs.inputs.splice(-1, 1);
                newInputs.inputs = [...newInputs.inputs ?? [], ...customAttributesInputs ?? []];

                if(oneFromLastIndex && lastItem.length) {
                    newInputs.inputs.push(lastItem[0]);
                }
            } else {
                newInputs.inputs = [...newInputs.inputs ?? [], ...customAttributesInputs ?? []];
            }
        }

    }


    return newInputs;
}

export const transformCustomAttributes = (attributes, prefix = 'custom_attributes.') => {
    const inputs = [];

    for (let i in attributes) {
        inputs.push({
            id: prefix + (attributes[i].id ?? 'input_id'),
            title: attributes[i].title ?? 'input title',
            description: attributes[i].description ?? attributes[i].title,
            type: attributes[i].type ?? 'text'
        })
    }

    return inputs;
}

export const getSelectedIds = (selectedItems, multiple) => {
    if (multiple && Array.isArray(selectedItems)) {
        return selectedItems.map(item => item.id);
    }

    return selectedItems?.id ?? null;
}


export const sortDraggedItem = (array, sortedIndex, placingIndex, successCallback) => {
    const draggedValues = [...array];
    const sortedItem = draggedValues.splice(sortedIndex, 1)[0];
    draggedValues.splice(placingIndex, 0, sortedItem)

    if (typeof successCallback === 'function') {
        successCallback(draggedValues);
    }

    return draggedValues;
}

export function truncateString(str, length = 24) {
    if (!str) {
        return "";
    }
    
    if (str.length > 24) {
        return str.substring(0, 21) + '...';
    }

    return str;
}
