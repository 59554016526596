import {config} from "../config";
import {CustomAlert, showToast} from "../Components/CustomAlerts";

export function getSliders(successCallback, errorCallback, loaderCallback) {
    loaderCallback(true)
    axios.get(config.homeSlider.endpoints.get)
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}


export function storeSlider(data, successCallback, errorCallback, loaderCallback) {
    loaderCallback(true)
    axios.post(config.homeSlider.endpoints.store, data)
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function updateSlider(slider, data, successCallback, errorCallback, loaderCallback) {
    loaderCallback(true)
    axios.patch(config.homeSlider.endpoints.update(slider.id), data)
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function deleteSlider(slider, successCallback, loaderCallback) {
    const deleteFunction = () => {
        axios.delete(config.homeSlider.endpoints.delete(slider.id))
            .then((res) => {
                showToast("Image successfully deleted");
                successCallback(res.data);
            })
            .catch(err => console.error(err?.response?.data?.message ?? 'Error Occurred'))
            .finally(() => loaderCallback(false))
    }

    if(slider.status === 'Removed') {
        CustomAlert(
            `Permanently Delete "${slider.title}" Slider`,
            "Are you sure you want to <strong> permanently delete </strong> this slider?",
            deleteFunction,
            null,
            {
                imageUrl: slider.image.url
            }
        );
    } else {
        deleteFunction();
    }
}

export function restoreSlider(slider, successCallback, loaderCallback) {
    loaderCallback(true)
    axios.put(config.homeSlider.endpoints.restore(slider.id))
        .then(res => {
            showToast("Image successfully restored");
            successCallback(res.data);
        })
        .catch(err => console.error(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}

export function onSliderDragEnd(sliders, sortedIndex, placedIndex) {
    let newSliders = [...sliders];
    let sortedItem = newSliders.splice(sortedIndex, 1)[0];
    newSliders.splice(placedIndex, 0, sortedItem)
    return newSliders;
}

export function saveSortedItems(sliders, successCallback, errorCallback, loaderCallback) {
    const data = sliders.map((item, index) => {
        return {id: item.id, sort: index}
    })

    loaderCallback(true)
    axios.put(config.homeSlider.endpoints.sort, {data: data})
        .then(res => successCallback(res.data))
        .catch(err => errorCallback(err?.response?.data?.message ?? 'Error Occurred'))
        .finally(() => loaderCallback(false))
}
